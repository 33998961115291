<template>
    <nav class="main-menu">
        <ul>

            <li :class="{ 'current': currentMenuItem === 'start' }">
                <router-link to="/">Главная</router-link>
            </li>
            
            <li class="has-children" :class="{ 'current': currentMenuItem === 'song' }">
                <router-link to="/song">Песня</router-link>
                <span class="icon-menu">
                    <svg class="svg-icon icon-arrow-down ">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                    </svg>
                </span>

                <ul>

                    <li class="has-children" :class="{ 'current': currentMenuItem === 'song-create' }">
                        <router-link to="/song-create">Создать</router-link>
                        <span class="icon-menu">
                            <svg class="svg-icon icon-arrow-down ">
                                <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                            </svg>
                        </span>

                        <ul>

                            <li class="has-children" :class="{ 'current': currentMenuItem === 'song-create-new' }">
                                <router-link to="/song-create-new">Создать новую</router-link>
                                    <span class="icon-menu">
                                        <svg class="svg-icon icon-arrow-down ">
                                            <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                                        </svg>
                                    </span>

                                <ul>

                                    <li :class="{ 'current': currentMenuItem === 'song-from-scratch' }">
                                        <router-link to="/song-from-scratch">Пишем с нуля</router-link>
                                    </li>

                                    <li :class="{ 'current': currentMenuItem === 'song-from-template' }">
                                        <router-link to="/song-from-template">Есть заготовка</router-link>
                                    </li>

                                </ul>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'song-buy' }">
                                <router-link to="/song-buy">Купить готовую</router-link>
                            </li>

                        </ul>
                    </li>

                    <li :class="{ 'current': currentMenuItem === 'song-release' }">
                        <router-link to="/song-release">Выпустить</router-link>
                    </li>

                    <li :class="{ 'current': currentMenuItem === 'song-buy' }">
                        <router-link to="/song-promo">Продвинуть</router-link>
                    </li>

                </ul>
            </li>

            <li class="has-children" :class="{ 'current': currentMenuItem === 'video' }">
                <router-link to="/video">Видео</router-link>
                <span class="icon-menu">
                    <svg class="svg-icon icon-arrow-down ">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                    </svg>
                </span>

                <ul>

                    <li class="has-children" :class="{ 'current': currentMenuItem === 'video-create' }">
                        <router-link to="/video-create">Создать</router-link>
                        <span class="icon-menu">
                            <svg class="svg-icon icon-arrow-down ">
                                <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                            </svg>
                        </span>

                        <ul>

                            <li :class="{ 'current': currentMenuItem === 'video-lyric' }">
                                <router-link to="/video-lyric">Lyric видео</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'video-clip' }">
                                <router-link to="/video-clip">Видео клип</router-link>
                            </li>

                        </ul>
                    </li>

                    <li :class="{ 'current': currentMenuItem === 'video-promo' }">
                        <router-link to="/video-promo">Продвинуть</router-link>
                    </li>

                </ul>
            </li>

            <li class="has-children" :class="{ 'current': currentMenuItem === 'artist' }">
                <router-link to="/artist">Артист</router-link>
                <span class="icon-menu">
                    <svg class="svg-icon icon-arrow-down ">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                    </svg>
                </span>

                <ul>

                    <li class="has-children" :class="{ 'current': currentMenuItem === 'artist-naming' }">
                        <router-link to="/artist-naming">Нейминг</router-link>
                        <span class="icon-menu">
                            <svg class="svg-icon icon-arrow-down ">
                                <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                            </svg>
                        </span>

                        <ul>

                            <li :class="{ 'current': currentMenuItem === 'artist-name' }">
                                <router-link to="/artist-name">Название</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-trademark' }">
                                <router-link to="/artist-trademark">Торговая марка</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-domain' }">
                                <router-link to="/artist-domain">Регистрация доменов</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-social-media' }">
                                <router-link to="/artist-social-media">Регистрация социальных сетей</router-link>
                            </li>

                        </ul>
                    </li>

                    <li class="has-children" :class="{ 'current': currentMenuItem === 'artist-design' }">
                        <router-link to="/artist-design">Графика и Дизайн</router-link>
                        <span class="icon-menu">
                            <svg class="svg-icon icon-arrow-down ">
                                <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                            </svg>
                        </span>

                        <ul>

                            <li :class="{ 'current': currentMenuItem === 'artist-logo' }">
                                <router-link to="/artist-logo">Логотип</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-brandbook' }">
                                <router-link to="/artist-brandbook">Брендбук</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-cover-image' }">
                                <router-link to="/artist-cover-image">Обложка сингла</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-account-design' }">
                                <router-link to="/artist-account-design">Дизайн соцсетей</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-web-dev' }">
                                <router-link to="/artist-web-dev">Разработка сайта</router-link>
                            </li>

                        </ul>
                    </li>

                    <li class="has-children" :class="{ 'current': currentMenuItem === 'artist-promo' }">
                        <router-link to="/artist-promo">PR и Promo</router-link>
                        <span class="icon-menu">
                            <svg class="svg-icon icon-arrow-down ">
                                <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                            </svg>
                        </span>

                        <ul>

                            <li :class="{ 'current': currentMenuItem === 'artist-vote' }">
                                <router-link to="/artist-vote">Голосования</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-wiki' }">
                                <router-link to="/artist-wiki">Википедия</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-press-release' }">
                                <router-link to="/artist-press-release">Прессрелиз</router-link>
                            </li>

                            <li :class="{ 'current': currentMenuItem === 'artist-mass-media' }">
                                <router-link to="/artist-mass-media">СМИ</router-link>
                            </li>

                            <li class="has-children" :class="{ 'current': currentMenuItem === 'artist-reputation' }">
                               <router-link to="/artist-reputation">Репутация</router-link>
                                <span class="icon-menu">
                                    <svg class="svg-icon icon-arrow-down ">
                                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-arrow-down"></use>
                                    </svg>
                                </span>

                                <ul>

                                    <li :class="{ 'current': currentMenuItem === 'artist-account-design' }">
                                        <router-link to="/artist-monitoring">Мониторинг</router-link>
                                    </li>
                                
                                    <li :class="{ 'current': currentMenuItem === 'artist-rating-up' }">
                                        <router-link to="/artist-rating-up">Удаление негатива</router-link>
                                    </li>
                                    
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>
<script>
export default ({
    name: "main-menu",
    data() {
        return {
            currentMenuItem: []
        }
    },
    mounted() { 
        this.hotRouter() 
    },
    watch: { 
        '$route'() { 
            this.hotRouter() 
        } 
    },
    methods: {
        hotRouter() { 
            this.currentMenuItem = this.$route.name;
        }
    }
})
</script>