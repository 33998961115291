<template>
	<header class="header header-main">
		
		<div class="menu-button">
			<button class="btn-menu" type="button">
				<span class="line"></span>
				<span class="line"></span>
				<span class="line"></span>
			</button>
		</div>

		<div class="left-sidebar">
			<div class="left-sidebar__inner">
				<div class="left-sidebar__top">
					
					<user-box v-if="isAuth"></user-box>
					<reg-box v-else></reg-box>

					<button class="btn-close">
						<svg class="svg-icon icon-close ">
							<use xlink:href="@/assets/img/spriteSvg.svg#sprite-close"></use>
						</svg>
					</button>
					
				</div>
				<div class="left-sidebar__middle">

					<main-menu></main-menu>

				</div>
				<div class="left-sidebar__bottom">
					<nav class="support-menu">
						<ul>
							<li><router-link to="/contacts">Контакты</router-link></li>
							<li>
								<router-link to="/legal">Юридическая информация</router-link>
							</li>
							<li>
								<router-link to="/policy">Политика конфиденциальности</router-link>
							</li>
						</ul>
					</nav>
					<div class="copyright">
						<p>2021 © Copyright. Все права защищены.</p>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
import MainMenu from "@/components/MainMenu";
import UserBox from "@/components/Misc/UserBox";
import RegBox from "@/components/Misc/RegBox";
import { mapGetters } from 'vuex';

export default {
	name: "layout-header",
	computed: {
		...mapGetters([
			'isAuth',
		])
	},
	components: {
		RegBox,
		UserBox,
		MainMenu
	}
};
</script>
