<template>
    <div class="main-content">
        <div class="center-block center-block--form">
            <div class="logo-wrap logo-wrap--top">
                <div class="logo">
                    <img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193">
                </div>
            </div>
            <div class="message-block _custom">
                <h1 class="message-block__title">Завершение регистрации</h1>

                <p class="message-block__text">
                    Мы отправили вам ссылку для подтверждения аккаунта на адрес <strong>{{email}}</strong>
                </p>
                
                <p  class="message-block__text">
                    <strong> Ничего не пришло? </strong>
                    <span
                        v-if="!timer.timerInterval"
                        @click.prevent="resend"
                        style="cursor: pointer; color: blue;"
                    >
                      Отправить повторно
                    </span>
                    <span v-else>
                      Повторно можно отправить через {{timer.current}} секунд
                    </span>
                </p>

                <div class="section-button center">
                    <router-link class="btn btn-lilac button" to="/">На главную</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "msg-register",
  data() {
    return {
      email: '',
      timer: {
        current: 0,
        start: 60,
        timerInterval: null
      }
    }
  },
  methods: {
    resend() {
      if(this.timer.timerInterval !== null) return;

      this.$store.dispatch('resendConfirmEmail', {
        email: this.email
      });

      this.timer.current = this.timer.start;
      this.timer.timerInterval = setInterval(() => {
        if(this.timer.current === 0) {
          clearInterval(this.timer.timerInterval);
          this.timer.timerInterval = null;
        }
        this.timer.current--;
      }, 1000);
    }
  },
  mounted() {
    this.email = localStorage.getItem('user_email');
    if(!this.email) {
      this.$router.push('/');
    }
    localStorage.removeItem('user_email');
  }
}
</script>