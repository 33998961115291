<template>
    
        <div class="main-content main-content--profile">
          <div class="edit-wrap">
            <div class="center-block center-block--profile tabs">
              <h1 class="center-block__title">Личный кабинет</h1>
              <div>
                <tab-container></tab-container>
              </div>
            </div>
            <div class="section-button section-button--center">
                <router-link class="btn btn-lilac button" to="/">Вернуться на главную</router-link>
            </div>
          </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabContainer from "@/components/Profile/TabContainer";

export default {
    name: "Profile",
    computed: mapGetters(['currentUser']),
    components: {
      TabContainer
    },
    async mounted() {
        await this.$store.dispatch('me');
    }
}
</script>