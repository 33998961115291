import Http from "@/http";
import ErrorFetcher from "@/helpers/error-fetcher";

export default {
    state: {
        orderCreating: false,
        attemptCreationOrder: false,
        orderCreationErrors: []
    },
    getters: {
        orderCreating(state) {
            return state.orderCreating;
        },
        attemptCreationOrder(state) {
            return state.attemptCreationOrder;
        },
        firstOrderError(state) {
            return state.orderCreationErrors.length ? state.orderCreationErrors[0] : '';
        },
        localOrders(state) {
            return localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : [];
        }
    },
    mutations: {
        setOrderCreating(state, value) {
            state.orderCreating = value
        },
        setAttemptCreationOrder(state, value) {
            state.attemptCreationOrder = value;
        },
        setOrderCreationErrors(state, errors) {
            state.orderCreationErrors = errors;
        },
        resetOrderForm(state) {
            state.orderCreating = false;
            state.attemptCreationOrder = false;
            state.orderCreatingErrors = [];
        },
        addLocalOrder(state, { order, getters }) {
            let localOrders = getters.localOrders;
            localOrders.push(order);
            localStorage.setItem('orders', JSON.stringify(localOrders));
        }
    },
    actions: {
        async createOrder({ commit, getters, rootGetters }, order) {
            commit('setOrderCreating', true);

            try {
                if (rootGetters.isAuth) {
                    await Http.post("/api/v1/orders", order);
                } else {
                    commit('addLocalOrder', { order, getters })
                }
            } catch (e) {
                let error = ErrorFetcher.firstError(e.response);
                commit('setOrderCreationErrors', [error]);
            } finally {
                commit('setOrderCreating', false);
            }
        }
    }
}