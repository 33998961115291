<template>
    <div class="reg-box">
        <div class="buttons-group">
            <div class="buttons-group__item">
                <router-link class="btn btn-link" to="/login">
                    <svg class="svg-icon icon-security ">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-security"></use>
                    </svg> 
                    
                    Войти

                </router-link>
            </div>
            <div class="buttons-group__item">
                <router-link class="btn btn-link" to="/register">
                    <svg class="svg-icon icon-communications">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-communications"></use>
                    </svg> 
                    
                    Регистрация

                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "reg-box",
    }
</script>