import Http from "@/http";

export default {
    actions: {
        async verifyPhoneStepOne({}, data) {
            await Http.post('/api/v1/verify-phone/step-one', data);
        },
        async verifyPhoneStepTwo({}, data) {
            await Http.post('/api/v1/verify-phone/step-two', data);
        },
        async verifyPhoneResend({}) {
            await Http.post('/api/v1/verify-phone/resend');
        }
    }
}