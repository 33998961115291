import Http from "@/http";

export default {
    actions: {
        async createUser({ getters, rootGetters, commit }, data) {
            data.orders = rootGetters.localOrders;
            await Http.post('/api/v1/users', data);
        },
        async confirmEmail({}, data) {
            await Http.post('/api/v1/confirm-email', data);
        },
        async resendConfirmEmail({}, data) {
            await Http.post('/api/v1/confirm-email/resend', data);
        }
    }
}