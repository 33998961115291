<template>
	<div class="main-content">
		<div class="center-block center-block--form center-block--reg">
			<div class="logo-wrap logo-wrap--top">
				<div class="logo">
					<img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193"/>
				</div>
			</div>
			<h1 class="center-block__title">Регистрация</h1>

			<form-register></form-register>

		</div>
	</div>
</template>
<script>
import FormRegister from '@/components/Forms/Auth/FormRegister';
export default {
    name: "register",
	components: { FormRegister }
}
</script>
<style lang="scss">
	.center-block {
		&--reg {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}
	}
</style>