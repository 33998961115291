<template>

    <div class="spinner-container">
        <div class="spinner"></div>
    </div>
    
</template>
<script lang="ts">
export default({
    name: "loading-spinner",
})
</script>
<style lang="scss">

    .spinner-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }

    .spinner {
        width: 10rem;
        height: 10rem;
        border-top: 1rem solid rgba(#b90ccc, 0.8);
        border-right: 1rem solid transparent;
        border-radius: 50%;
        margin: auto;
        animation: spinner 0.6s linear infinite;
    }

    @keyframes spinner {
        100%{ 
            transform: rotate(360deg) 
        }
    }

</style>