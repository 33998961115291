<template>
	<div class="main-content">
		<div class="center-block center-block--form">
			<div class="logo-wrap logo-wrap--top">
				<div class="logo">
					<img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193"/>
				</div>
			</div>
            
			<form-new-password></form-new-password>

		</div>
	</div>
</template>
<script>
import FormNewPassword from "@/components/Forms/Auth/FormNewPassword";
export default {
	name: "new-password",
	components: { FormNewPassword }
};
</script>
