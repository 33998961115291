<template>
    <div class="main-content">
        <div class="center-block center-block--form">
            <div class="logo-wrap logo-wrap--top">
                <div class="logo">
                    <img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193">
                </div>
            </div>
            <div class="message-block">
                <h1 class="message-block__title">Ваш заказ принят!</h1>
                <p class="message-block__text">В ближайшее время с Вами <br>свяжется менеджер.</p>
                <div class="section-button center">
                    <router-link class="btn btn-lilac button" to="/">На главную</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "msg-order",
}
</script>