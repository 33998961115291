<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Разработка сайта">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none;" class="form_key">wp</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="select">Жанр, стиль, направление</label>
            <div class="select-wrap">
                <select class="form-select" name="select" data-name="song_style" id="select" data-msg-required="Выберите один из вариантов" required="" v-model="formData.song_style">
                    <option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
                    <option value="Рок-музыка">Рок-музыка</option>
                    <option value="Джаз">Джаз</option>
                </select>
            </div>
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Цели и задачи сайта</label>
            <textarea class="form-input form-textarea" name="text1" data-name="website_tasks" id="text1" placeholder="Например, имиджевый сайт музыкальной группы или интернет-магазин или сайт для продвижения конкретного сингла / видоеклипа." rows="3" data-msg-required="Заполните поле цели и задачи" required="" v-model="formData.website_tasks"></textarea>
        </div>
        <div class="form-row">
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="address">Желаемый адрес сайта</label>
                    <input class="form-input" name="address" data-name="desired_domain" id="address" placeholder="Адрес сайта" data-msg-required="Заполните поле адрес сайта" required="" v-model="formData.desired_domain">
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="access1">Доступ к сайту</label>
                    <input class="form-input" name="access1" data-name="access_to_website" id="access1" placeholder="Доступ к сайту" data-msg-required="Заполните поле доступ к сайту" required="" v-model="formData.access_to_website">
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="access2">Доступ к хостингу</label>
                    <input class="form-input" name="access2" data-name="access_to_hosting" id="access2" placeholder="Доступ к хостингу" data-msg-required="Заполните поле доступ к хостингу" required="" v-model="formData.access_to_hosting">
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="typeSite">Тип сайта</label>
                    <div class="select-wrap">
                        <select class="form-select" name="typeSite" data-name="website_type" id="typeSite" data-msg-required="Выберите один из вариантов" required="" v-model="formData.website_type">
                            <option value="Лендинг">Лендинг</option>
                            <option value="Одностраничный">Одностраничный</option>
                            <option value="Многостраничный">Многостраничный</option>
                            <option value="Интернет-магазин">Интернет-магазин</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-item">
            <label class="form-label" for="structure">Структура сайта</label>
            <textarea class="form-input form-textarea" name="structure" data-name="website_structure" id="structure" placeholder="Опишите структуру сайта" rows="3" data-msg-required="Заполните поле цели и задачи" required="" v-model="formData.website_structure"></textarea>
            <p class="field-text">В свободной форме опишите из каких основных разделов будет состоять сайт.</p>
        </div>
        <div class="form-row">
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужна ли мобильная версия сайта?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_mobile_version', true)" type="radio" name="mobil" value="Да" id="m1">
                            <label for="m1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_mobile_version', false)" type="radio" name="mobil" value="Нет" id="m2">
                            <label for="m2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужен ли слайдер на главной странице?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_slider', true)" type="radio" name="slider" value="Да" id="s1">
                            <label for="s1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_slider', false)" type="radio" name="slider" value="Нет" id="s2">
                            <label for="s2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужен ли поиск на сайте?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_search', true)" type="radio" name="search" value="Да" id="sr1">
                            <label for="sr1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_search', false)" type="radio" name="search" value="Нет" id="sr2">
                            <label for="sr2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Будут ли на сайте продаваться кикие-то товары?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_store', true)" type="radio" name="products" value="Да" id="p1">
                            <label for="p1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_store', false)" type="radio" name="products" value="Нет" id="p2">
                            <label for="p2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужна ли на сайте форма оплаты онлайн?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_payment', true)" type="radio" name="online" value="Да" id="ol1">
                            <label for="ol1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_payment', false)" type="radio" name="online" value="Нет" id="ol2">
                            <label for="ol2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужна ли на сайте форма обратной связи?</div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_feedback', true)" type="radio" name="call" value="Да" id="c1">
                            <label for="c1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_feedback', false)" type="radio" name="call" value="Нет" id="c2">
                            <label for="c2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <div class="form-label">Нужна ли на сайте интерактивная карта проезда? <span class="small-text">(Например, схема проезда к офису.)</span></div>
                    <div class="radio-group radio-group--line">
                        <div class="radio-item">
                            <input @input="checked('with_map', true)" type="radio" name="map" value="Да" id="mp1">
                            <label for="mp1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                        </div>
                        <div class="radio-item">
                            <input @input="checked('with_map', false)" type="radio" name="map" value="Нет" id="mp2">
                            <label for="mp2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-item">
            <label class="form-label" for="example1">Примеры сайтов, которые Вам нравятся</label>
            <textarea class="form-input form-textarea" name="example1" data-name="website_examples" id="example1" placeholder="Ссылки на сайты через запятую" rows="3" data-msg-required="Заполните поле примеры сайтов" required="" v-model="formData.website_examples"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label" for="example2">Примеры сайтов, которые Вам не нравятся</label>
            <textarea class="form-input form-textarea" name="example2" data-name="website_bad_examples" id="example2" placeholder="Ссылки на сайты через запятую" rows="3" data-msg-required="Заполните поле примеры сайтов" required="" v-model="formData.website_bad_examples"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label" for="example3">Примеры сайтов-конкурентов</label>
            <textarea class="form-input form-textarea" name="example3" data-name="website_competitors_examples" id="example3" placeholder="Ссылки на сайты через запятую" rows="3" data-msg-required="Заполните поле примеры сайтов" required="" v-model="formData.website_competitors_examples"></textarea>
        </div>
        <div class="form-item">
            <div class="form-label">Есть ли у вас фирменный стиль и логотип?</div>
            <div class="radio-group radio-group--line">
                <div class="radio-item">
                    <input @input="checked('with_logo', true)" type="radio" name="logo" value="Да" id="l1">
                    <label for="l1"><span class="check-icon"></span><span class="check-text">Да</span></label>
                </div>
                <div class="radio-item">
                    <input @input="checked('with_logo', false)" type="radio" name="logo" value="Нет" id="l2">
                    <label for="l2"><span class="check-icon"></span><span class="check-text">Нет</span></label>
                </div>
            </div>
        </div>
        <div class="form-item">
            <label class="form-label" for="term">Сроки работ</label>
            <input class="form-input" name="term" data-name="deadline" id="term" placeholder="Сроки разработки" data-msg-required="Заполните поле сроки работ" required="" v-model="formData.deadline">
        </div>
         <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "wd",
          project_name: "",
          song_style: "Хип-хоп, Рэп",
          website_tasks: "",
          desired_domain: "",
          access_to_website: "",
          access_to_hosting: "",
          website_type: "Лендинг",
          website_structure: "",
          website_examples: "",
          website_bad_examples: "",
          website_competitors_examples: "",
          deadline: "",
          with_feedback: false,
          with_map: false,
          with_mobile_version: false,
          with_payment: false,
          with_search: false,
          with_slider: false,
          with_store: false,
          with_logo: false
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    },
    async checked(name, value) {
      this.formData[name] = value;
    }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>