 <template>
    <form @submit.prevent="submit" class="form-login">

        <div class="message-block _custom">
            <h1 class="message-block__title">Номер телефона</h1>
        </div>
        
        <div v-if="stepOne" class="form-item">
            <masked-input
                v-model="stepOneForm.phone"
                class="form-input"
                :class="{error: phoneError}"
                type="tel"
                name="phone"
                placeholder="Введите номер"
                mask="\+\7(111)-111-11-11"
            />
          <label v-if="phoneError" class="error" for="phone">{{phoneError}}</label>
        </div>

        <div v-else class="form-item">
            <input
                v-model="stepTwoForm.code"
                class="form-input"
                :class="{error: codeError}"
                type="text"
                name="code"
                placeholder="Введите код из смс"
            >
          <label v-if="codeError" class="error" for="phone">{{codeError}}</label>
        </div>

        <div v-if="stepOne" class="alert alert-msg">
          Введит ваш номер телефона, мы отправим на него смс с кодом для подтверждения
        </div>

         <div v-if="!stepOne" class="alert alert-msg">
           <p v-if="!timer.timerInterval">Не пришел код? <span @click="resend" style="cursor: pointer; text-decoration: underline">Отправить повторно</span></p>
           <p v-else>Отправить повторно через {{timer.current}}</p>
        </div>

        <div class="form-button center">
            <button class="btn btn-lilac button" type="submit">Отправить</button>
        </div>

        <loading-spinner v-show="sending"></loading-spinner>

    </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import MaskedInput from 'vue-masked-input'
export default {
  name: "form-new-password",
  data() {
    return {
      sending: false,
      stepOne: true,

      stepOneForm: {
        phone: '',
        attemptSend: false,
        backendErrors: {
          phone: []
        }
      },

      timer: {
        current: 0,
        start: 60,
        timerInterval: null
      },

      stepTwoForm: {
        code: '',
        attemptSend: false,
        backendErrors: {
          code: []
        }
      }
    }
  },
  components: {
    LoadingSpinner,
    MaskedInput
    },
  validations: {
    stepOneForm: {
      phone: {
        required,
        phoneNumber: value => {
          if(!value) return true;

          return value.search('^\\+?[78][-\\(]?\\d{3}\\)?-?\\d{3}-?\\d{2}-?\\d{2}$') > -1;
        }
      }
    },
    stepTwoForm: {
      code: {
        required
      }
    }
  },
  computed: {
    phoneError() {
      if(this.stepOneForm.backendErrors.phone.length) {
        return this.stepOneForm.backendErrors.phone[0];
      }
      if(!this.$v.stepOneForm.phone.phoneNumber) {
        return 'Неверный формат телефона';
      }
      if(this.stepOneForm.attemptSend && !this.$v.stepOneForm.phone.required) {
        return 'Поле обязательно для заполнения';
      }
      return '';
    },
    codeError() {
      if(this.stepTwoForm.backendErrors.code.length) {
        return this.stepTwoForm.backendErrors.code[0];
      }
      if(this.stepOneForm.attemptSend && !this.$v.stepTwoForm.code.required) {
        return 'Поле обязательно для заполнения';
      }
      return '';
    }
  },
  methods: {
    async submit() {
      if(this.stepOne) {
        await this.stepOneSubmit();
      } else {
        await this.stepTwoSubmit();
      }
    },
    async resend() {
      if(this.timer.timerInterval !== null) return;

      await this.$store.dispatch('verifyPhoneResend');

      this.timer.current = this.timer.start;
      this.timer.timerInterval = setInterval(() => {
        if(this.timer.current === 0) {
          clearInterval(this.timer.timerInterval);
          this.timer.timerInterval = null;
        }
        this.timer.current--;
      }, 1000);
    },
    async stepOneSubmit() {
      if(this.sending) return;
      this.sending = true;

      this.stepOneForm.attemptSend = true;

      try {
        if(this.$v.stepOneForm.phone.required && this.$v.stepOneForm.phone.phoneNumber) {
          await this.$store.dispatch('verifyPhoneStepOne', this.stepOneForm);
          this.stepOne = false;
        }
      } catch(e) {
        this.stepOneForm.backendErrors = e.response.data.errors;
      } finally {
        this.sending = false;
      }
    },
    async stepTwoSubmit() {
      if(this.sending) return;
      this.sending = true;

      this.stepTwoForm.attemptSend = true;

      try {
        if(this.$v.stepTwoForm.code.required) {
          await this.$store.dispatch('verifyPhoneStepTwo', this.stepTwoForm);
          await this.$router.push('/profile');
        }
      } catch(e) {
        this.stepTwoForm.backendErrors.code = ['Неверный код'];
      } finally {
        this.sending = false;
      }
    }
  }
}
</script>