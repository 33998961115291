<template>
    <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h1 class="section-title">Политика конфиденциальности</h1>
                    <p class="section-descr">Политика в отношении обработки персональных данных</p>
                </div>
                <article class="section-article">
                    <h3>1. Общие положения </h3>
                    <p>
                        1.1. Политика обработки персональных данных (далее - "Политика") издана и применяется в Обществе с ограниченной ответственностью «ЭЛСИ ДИЗАЙН» (далее - "Оператор") в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".
                    </p>
                    <p>
                        Настоящая Политика определяет порядок и условия Оператора в отношении обработки персональных данных и определяют положения, направленные на предотвращение и выявление нарушений законодательства Российской Федерации в области персональных данных..
                    </p>
                    <p>
                        Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящей Политикой, разрешаются в соответствии с действующим законодательством Российской Федерации.
                    </p>
                    <p>
                        1.2. Целью обработки персональных данных является:
                    </p>
                    <p>
                        предоставление субъектам персональных данных услуг Оператора на сайте <a href="https://yaproducer.com/">https://yaproducer.com</a>, на котором размещен и функционирует проект Оператора «Я.Продюсер», направленный на создание, распространение и рекламу изображений, музыкальных и видео файлов в сети Интернет;
                    </p>
                    <p>
                        обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                    </p>
                    <p>
                        1.3. Обработка организована Оператором на принципах:
                    </p>
                    <p>
                        законности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p>
                        достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;
                    </p>
                    <p>
                        обработки только персональных данных, которые отвечают целям их обработки;
                    </p>
                    <p>
                        соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;
                    </p>
                    <p>
                        недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;
                    </p>
                    <p>
                        обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;
                    </p>
                    <p>
                        хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
                    </p>
                    <p>
                        1.4. Персональные данные обрабатываются с использованием средств автоматизации.
                    </p>
                    <p>
                        1.5. Оператор обрабатывает следующие персональные данные:
                    </p>
                    <p>
                        1.5.1. Обязательные, без которых невозможна регистрация на сайте https://yaproducer.com:
                    </p>
                    <p>
                        фамилия, имя
                    </p>
                    <p>
                        адрес электронной почты
                    </p>
                    <p>
                        номер мобильного телефона
                    </p>
                    <p>
                        1.5.2. Добровольные, то есть данные, которые пользователь указывает в своем аккаунте по собственному изъявлению:
                    </p>
                    <p>
                        Город
                    </p>
                    <p>
                        Страна
                    </p>
                    <p>
                        Дата рождения
                    </p>
                    <p>
                        Информация о себе в свободной форме
                    </p>
                    <p>
                        1.5.2.1. При этом некоторые обязательные и/или добровольные персональные данные, по личному волеизъявлению пользователя на сайте <a href="https://yaproducer.com/">https://yaproducer.com</a> делаются таким пользователем общедоступными для любых третьих лиц в порядке п.п.10, п.1 статьи 6 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".
                    </p>
                    <p>
                        1.6. При обработке персональных данных Оператор применяет все необходимые правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".
                    </p>
                    <p>
                        1.7. Оператор не раскрывает третьим лицам и не распространяет персональные данные без согласия субъекта персональных данных, кроме случает, если это предусмотрено действующим законодательством РФ и настоящей Политикой.
                    </p>
                    <p>
                        1.8. Оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Оператором требований Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса Российской Федерации. Соотношение указанного вреда и принимаемых Оператором мер, направленных на предупреждение, недопущение и/или устранение его последствий, определяется локальными актами Оператора.
                    </p>
                    <p>
                        1.9. Условия обработки персональных данных Оператором:
                    </p>
                    <p>
                        1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; персональные данные обрабатываются Оператором после заключения субъектом персональных данных пользовательского соглашение (договора – оферты <a href="https://yaproducer.com/policy">https://yaproducer.com/legal</a>) на предоставление такому субъекту права на использования сайта <a href="https://yaproducer.com/">https://yaproducer.com</a>. Согласно п. 5 статьи 6 Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных», согласие субъекта персональных данных не требуется, так как субъект заключает соглашение по собственной инициативе и является выгодоприобретателем по заключенному им соглашению.
                    </p>
                    <p>
                        2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Оператор не передает персональные данные субъекта третьим лицам.
                    </p>
                    <p>
                        1.10. Хранение персональных данных субъектов, которые являются пользователями сайта <a href="https://yaproducer.com/">https://yaproducer.com</a> осуществляется в форме, позволяющей определить субъекта персональных данных. Персональные данные подлежат уничтожению по достижении целей обработки. Удаление персональных данных осуществляется посредством удаления учетной записи на сайте <a href="https://yaproducer.com/">https://yaproducer.com</a>, после чего такая запись не подлежит восстановлению.
                    </p>
                    <p>
                        1.11. Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.
                    </p>
                    <p>
                        1.12. Обработка персональных данных осуществляется автоматизировано, при помощи специального программного модуля сайта <a href="https://yaproducer.com/">https://yaproducer.com</a> который обрабатывает разделы данных, заполняемых пользователями, и на основании которых пользователю создается личный кабинет (аккаунт).
                    </p>
                    <h3>
                    2. Ответственный за обработку персональных данных </h3>
                    <p>
                        2.1. При обработке персональных данных Оператор издает соответствующий приказ о назначении ответственного за обработку персональных данных.
                    </p>
                    <p>
                        2.2. Ответственный за обработку персональных данных подчиняется исполнительному органу Оператора — Генеральному директору, контролирует процесс обработки персональных данных и обеспечивает реализацию всех положений настоящей Политики, относящихся к его компетенции.
                    </p>
                    <p>
                        2.3. Ответственный за обработку персональных данных:
                    </p>
                    <p>
                        2.3.1. осуществляет внутренний контроль за соблюдением Оператором и его работниками законодательства Российской Федерации о персональных данных, в том числе требований к защите персональных данных.
                    </p>
                    <p>
                        2.3.2. контролирует прием и обработку обращений и запросов субъектов персональных данных или их представителей.
                    </p>
                    <p>
                        2.3.3. принимает меры для обнаружения фактов несанкционированного доступа к персональным данным и немедленного доведения этой информации до Генерального директора.
                    </p>
                    <p>
                        2.3.4. производит постоянный контроль за обеспечением уровня защищенности персональных данных.
                    </p>
                    <p>
                        2.3.5. знакомит под роспись работников Оператора, которые имеют доступ к персональным данным, с положениями законодательства РФ о персональных данных, в том числе требованиями о защите персональных данных, а также с локальными актами Оператора, определяющими порядок обработки Персональных данных.
                    </p>
                    <p>
                        2.3.6. осуществляет внутренний контроль и (или) аудит соответствия обработки персональных данных Федеральному закону от 27.07.2006 года № 152-ФЗ «О персональных данных» и принятым в соответствии с ним нормативными правовыми актами, требованиям к защите персональных данных, политике Оператора в отношении обработки персональных данных, локальным актам Оператора.
                    </p>
                    <h3>
                    3. Порядок обеспечения прав субъекта персональных данных </h3>
                    <p>
                        3.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных».
                    </p>
                    <p>
                        3.2. Оператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
                    </p>
                    <p>
                        3.3. Полномочия представителя на представление интересов каждого субъекта персональных данных подтверждаются соответствующей доверенностью.
                    </p>
                    <p>
                        3.4. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных в доступной форме без персональных данных, относящихся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных, в электронном виде. По требованию субъекта персональных данных они могут быть продублированы на бумаге. Доступная форма на бумаге должна быть заверена Генеральным директором.
                    </p>
                    <p>
                        3.5. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных или его представителю при личном обращении либо при получении запроса субъекта персональных данных или его представителя. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись субъекта персональных данных или его представителя. При наличии технической возможности запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.
                    </p>
                    <p>
                        3.6. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.
                    </p>
                    <p>
                        3.7. Оператор обязан немедленно прекратить по требованию субъекта персональных данных обработку его персональных данных, указанную в ч. 1 ст. 15 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
                    </p>
                    <p>
                        3.8. Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту своего расположения в рабочее время.
                    </p>
                    <p>
                        3.9. Оператор по запросу субъекта персональных данных или его уполномоченного представителя предоставляет для ознакомления локальные акты Оператора, касающиеся обработки персональных данных.
                    </p>
                </article>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "Policy"
}
</script>
<style scoped>
    a {
        color: #000;
        text-decoration: underline;
    }
</style>