import Codes from './codes';

export default {
    _messages: {
        500: 'Упс.. что-то пошло не так, попробуйте еще раз или позвоните в службу технической поддержки'
    },
    get(status) {
        if(Codes.isCriticalCode(status)) {
            return this._messages[status];
        }
    }
}