import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user";
import auth from "./modules/auth";
import order from "./modules/order";
import formOrder from "./modules/form-order";
import payment from "@/store/modules/payment";
import verifyPhone from "./modules/verify-phone";
import resetPassword from "./modules/reset-password";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        auth,
        order,
        payment,
        formOrder,
        verifyPhone,
        resetPassword
    }
})