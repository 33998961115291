<template>
    <div class="right-sidebar">
        <div class="bg-circle"></div>
        <div class="logo-wrap logo-wrap--sidebar">
            <div class="logo"><img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="320" height="397"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "RightSiderbar",
}
</script>