<template>
    <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h1 class="section-title">Юридическая информация</h1>
                    <p class="section-descr">Публичная оферта на заключение договора возмездного оказания услуг</p>
                </div>
                <article class="section-article">
                   <p>Редакция №1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Дата утверждения “12” августа 2021 г.</p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <p>
                        Настоящий документ является публичной офертой Общества с ограниченной ответственностью “ЭЛСИ ДИЗАЙН” (ИНН 7706773580, ОГРН 1127746332713), именуемого в дальнейшем Исполнитель и содержит все существенные условия договора возмездного оказания услуг. В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации (ГК РФ) в случае принятия изложенных ниже условий лицо, производящее акцепт настоящей оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте). В связи с изложенным выше, внимательно прочитайте текст настоящей публичной оферты. Если Вы не согласны с каким-либо пунктом оферты, Исполнитель предлагает Вам отказаться от заключения договора.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Термины и определения </h3>
                    <p>
                        1.1. Заказчик - физическое лицо, имеющее намерение заказать либо заказывающее услуги на основании Договора.
                    </p>
                    <p>
                        1.2. Договор – договор возмездного оказания Услуг, который заключается Заказчиком путем акцепта настоящей Оферты.
                    </p>
                    <p>
                        1.3. Услуги – услуги по предоставлению доступа к Сайту <a href="https://yaproducer.com/">https://yaproducer.com</a> с целью создания, распространения и рекламы изображений, музыкальных и видео файлов Заказчика в сфере шоу-бизнеса.
                    </p>
                    <p>
                        1.4. Стоимость услуги – это фиксированная стоимость услуг Исполнителя в рублях на Сайте с целью создания, распространения и рекламы изображений, музыкальных и видео файлов Заказчика в сфере шоу-бизнеса.
                    </p>
                    <p>
                        1.5. Сайт – совокупность программ для электронных вычислительных машин и иной информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством информационно-телекоммуникационной сети "Интернет" по доменному имени <a href="https://yaproducer.com/">https://yaproducer.com</a>. Лицом, самостоятельно и по своему усмотрению определяющим порядок использования сайта, в том числе порядок размещения информации на сайте, является Исполнитель.
                    </p>
                    <p>
                        1.6. Личный кабинет - персонализированная часть Сайта <a href="https://yaproducer.com/">https://yaproducer.com</a>, недоступная для всеобщего доступа, доступ к которой осуществляется путем ввода аутентификационных данных (логина – имени доступа и пароля – кода доступа). Заказчику в Личном кабинете предоставлена возможность оптимальным образом взаимодействовать с инфраструктурой Сайта, получать Услуги, пользоваться его иным функционалом, удаленно взаимодействовать с Исполнителем и обмениваться юридически значимыми документами.
                    </p>
                    <p>
                        1.7. Прайс-лист – это отдельная страница Сайта <a href="https://yaproducer.com/price">https://yaproducer.com/price</a>, где размещен весь перечень Услуг с указанием стоимости в рублях.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Акцепт Оферты </h3>
                    <p>
                        2.1. Акцептом публичной оферты Исполнителя является совершение физическим либо юридическим лицом всех следующих конклюдентных действий:
                    </p>
                    <p>
                        2.1.1. Регистрация на Сайте, которая включает в себя:
                    </p>
                    <p>
                        2.1.1.1. Предоставление Исполнителю с помощью регистрационных форм, размещенных на Сайте следующей информации: имени, адреса электронной почты, пароля для доступа к Личному кабинету;
                    </p>
                    <p>
                        2.1.1.2. Получение на предоставленный адрес электронной почты письма от Исполнителя, содержащего ссылку для подтверждения доступа к Личном кабинету;
                    </p>
                    <p>
                        2.1.1.3. Переход по содержащейся в письме ссылке;
                    </p>
                    <p>
                        2.1.1.4. Выбор необходимой услуги;
                    </p>
                    <p>
                        2.1.1.5. Оплата Услуг Исполнителя согласно Прайс-листу.
                    </p>
                    <p>
                        2.2. После совершения всех вышеуказанных действий между Исполнителем и Заказчиком заключается Договор на условиях, указанных в настоящей Оферте и оплаченной Исполнителем стоимости Услуг согласно Прайс-листу.
                    </p>
                    <p>
                        2.3. После завершения процесса регистрации Заказчика в Личном кабинете Заказчику присваивается идентификационный номер. Доступ Заказчика в Личный кабинет осуществляется с помощью уникального имени пользователя и пароля (уникальной последовательности символов неизвестной третьим лицам). Заказчик обязуется хранить пароль в тайне и не передавать данные своего аккаунта, в том числе уникальное имя пользователя и пароль третьим лицам.
                    </p>
                    <p>
                        2.3. Любые действия, совершенные с использованием логина и пароля Заказчика, считаются совершенными Заказчиком. В случае несанкционированного доступа к логину и паролю Заказчика и/или учетной записи Заказчика, Пользователь обязан немедленно сообщить об этом Исполнителю.
                    </p>
                    <p>
                        2.5. Заказчик соглашается получать от Исполнителя информационные и другие электронные сообщения на адрес электронный почты, указанный Заказчиком при регистрации.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Предмет Договора </h3>
                    <p>
                        3.1. Исполнитель оказывает Заказчику услуги по предоставлению доступа к Сайту с целью создания, распространения и рекламы изображений, музыкальных и видео файлов Заказчика в сфере шоу-бизнеса за счет средств Заказчика в объеме, согласованном Сторонами и указанном в Прайс-листе. Заказчик обязуется оплачивать оказываемые Исполнителем услуги и использовать Сайт на условиях Договора.
                    </p>
                    <p>
                        3.2. Исполнитель не оказывает Заказчику образовательных, консультационных услуг.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Порядок оплаты </h3>
                    <p>
                        4.1. Заказчик обязуется оплатить Услуги Исполнителя в порядке предварительной оплаты в размере, указанном в Прайс-листе способами, не запрещенными действующими законодательством, из числа указанных на Сайте.
                    </p>
                    <p>
                        4.2. Стоимость Услуг не включает в себя НДС в связи с применением Исполнителем упрощенной системы налогообложения.
                    </p>
                    <p>
                        4.3. Заказчик считается выполнившим свою обязанность по оплате Услуг в момент поступления денежных средств на расчетный счет Исполнителя.
                    </p>
                    <p>
                        4.4. В случае одностороннего отказа Заказчика от исполнения Договора, внесенная оплата (предоплата) перерасчету и возврату не подлежит, если Тарифным планом не предусмотрено иное.
                    </p>
                    <p>
                        4.5. Оплата Услуг может производиться посредством провайдера интернет-эквайринга, позволяющего оплачивать доступ различными способами. Исполнитель не несет ответственности за деятельность провайдера системы электронных платежей. <br>
                        4.6. Оплачивая Услуги, Заказчик соглашается с условиями, на которых провайдер электронных платежей предоставляет Заказчику свои услуги. Условия должны быть найдены Заказчиком самостоятельно на официальном сайте провайдера электронных платежей.<br>
                        4.7. Исполнитель не несет ответственности за возможные проблемы и/или неполадки при оплате Услуг, возникшие не по вине Исполнителя.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Порядок пользования Сайтом </h3>
                    <p>
                        5.1. Заказчик обязан пользоваться Сайтом и Личным кабинетом добросовестно, не нарушая законодательство Российской Федерации, права и свободы третьих лиц, нормы морали и нравственности.
                    </p>
                    <p>
                        5.2. Заказчик обязуется не загружать, не хранить, не публиковать, не распространять и не предоставлять доступ или иным образом использовать любую информацию, которая содержит угрозы, дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию или нарушает неприкосновенность частной жизни других Заказчиков или третьих лиц; нарушает права несовершеннолетних лиц; является вульгарной или непристойной, содержит порнографические изображения и тексты или сцены сексуального характера с участием несовершеннолетних; содержит описание средств и способов суицида, любое подстрекательство к его совершению; пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства; содержит экстремистские материалы; носит мошеннический характер.
                    </p>
                    <p>
                        Если в результате распространения указанной в данном пункте Договора информации Исполнителю были причинены убытки, Заказчик обязуется возместить их в полном объёме.
                    </p>
                    <p>
                        5.3. Заказчик обязуется хранить в тайне логин и пароль для доступа к Личному кабинету.
                    </p>
                    <p>
                        5.4. Заказчик обязуется предоставлять при регистрации достоверные, полные и актуальные данные, следить за их актуализацией. Исполнитель оставляет за собой право потребовать от Заказчика подтверждения данных, указанных при регистрации, в том числе потребовать документы, удостоверяющие личность.
                    </p>
                    <p>
                        5.5. Заказчик обязуется не использовать программное обеспечение и не осуществлять действия, направленные на нарушение нормального функционирования Сайта или Личного кабинета.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Порядок оказания услуг </h3>
                    <p>
                        6.1. Исполнитель оказывает Услуги в объеме и в сроки, указанные в Прайс-листе, дистанционно, в следующем порядке:
                    </p>
                    <p>
                        6.1.1. Исполнитель предоставляет через Личный кабинет Заказчику доступ к Услугам.
                    </p>
                    <p>
                        6.1.2. Исполнитель выполняет задачи Заказчика согласно описанию Услуге, указанной в Прайс-листе.
                    </p>
                    <p>
                        6.1.3. В случае возникновения дополнительных вопросов у Заказчика Исполнитель производит консультирование Заказчика через Личный кабинет на Сайте или с помощью иных средств коммуникации (мессенджеров, социальных сетей и т.д.).
                    </p>
                    <p>
                        6.2. Услуги считаются оказанными по истечении срока, указанного в Прайс-листе, если в течение 3 (трех) рабочих дней с момента окончания работ, Заказчик не заявил мотивированного возражения на качество и объем таких услуг посредством Личного кабинета. Стороны согласовали, что Акты сдачи-приемки услуг не оформляются.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Интеллектуальная собственность и ограничения при пользовании Сайтом </h3>
                    <p>
                        7.1. Сайт содержит результаты интеллектуальной деятельности, принадлежащие Исполнителю, а также третьим лицам, с которыми Исполнитель заключил соответствующие договоры.
                    </p>
                    <p>
                        7.2. Используя Сайт, Заказчик признает и соглашается с тем, что все содержимое Сайта и структура содержимого Сайта защищены авторским правом, правом на товарный знак и другими правами на результаты интеллектуальной деятельности, и что указанные права являются действительными и охраняются во всех формах, на всех носителях и в отношении всех технологий, как существующих в настоящее время, так и разработанных или созданных впоследствии. Никакие права на любое содержимое Сайта, принадлежащее Исполнителю, включая, помимо прочего, аудиовизуальные произведения, текстовые и графические материалы, программы для ЭВМ, товарные знаки не переходят к Заказчику в результате пользования Сайтом и заключения Договора.
                    </p>
                    <p>
                        7.3. Заказчику запрещается:
                    </p>
                    <p>
                        7.3.1. копировать, либо иным способом использовать программную часть Сайта, а также его дизайн;
                    </p>
                    <p>
                        7.3.2. размещать на Сайте персональные данные третьих лиц, без их согласия, в том числе домашние адреса, телефоны, паспортные данные, адреса электронной почты;
                    </p>
                    <p>
                        7.3.3. размещать на Сайте коммерческую рекламу, коммерческие предложения, агитационную информацию и любую другую навязчивую информацию, кроме случаев, когда размещение такой информации согласовано с Исполнителем;
                    </p>
                    <p>
                        7.3.4. изменять каким бы то ни было способом программную часть Сайта, совершать действия, направленные на изменение функционирования и работоспособности Сайта;
                    </p>
                    <p>
                        7.4. При цитировании материалов Сайта, если это прямо предусмотрено функциями Сайта, Заказчик обязуется указывать ссылку на Сайт.
                    </p>
                    <p>
                        7.5. Стороны согласовали, что в случае, если в процессе использования Сайта и оказания услуг, Заказчиком будут созданы и загружены на Сайт результаты его интеллектуальной деятельности, в том числе собственные музыкальные или видео произведения, а также изображения, то Заказчик предоставляет Исполнителю простую неисключительную безвозмездную лицензию на право использования таких результатов интеллектуальной деятельности на территории всего мира. Лицензия предоставляется на использование результатов интеллектуальной деятельности любыми способами, которые необходимы Исполнителю для надлежащего выполнения Исполнителем своих обязательств по Договору. Срок лицензии - до окончания срока действия Договора.
                    </p>
                    <h3>8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Порядок обработки персональных данных</h3>
                    <p>
                        8.1. Заказчик добровольно выражает свое согласие на обработку Исполнителем следующих персональных данных Заказчика: фамилия, имя, отчество, данные о документе, удостоверяющем личность, дата рождения, иные сведения, позволяющие прямо или косвенно идентифицировать Заказчика, сведения об итогах прохождения обучения, начисленных, уплаченных платежах за оказанные услуги, задолженности за полученные услуги.
                    </p>
                    <p>
                        8.2. Под обработкой персональных данных следует понимать действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                    </p>
                    <p>
                        8.3. Заказчик выражает свое согласие на обработку его персональных данных и (или) передачу сведений о Заказчике в целях: а) информационно - справочного обслуживания, в том числе для подготовки и распространения информации различными способами, в частности на магнитных носителях и с использованием средств телекоммуникаций, для оказания справочных и иных информационных услуг и (или) третьими лицами; b) рассмотрения претензий, предъявляемых Заказчиком к Исполнителю.
                    </p>
                    <p>
                        8.4. Заказчик вправе в любое время отозвать свое согласие на обработку его персональных данных, путем направления Исполнителю письменного заявления об отказе от обработки его персональных данных через Личный кабинет. Указанный отказ признается равнозначным уведомлению о досрочном расторжении Договора.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    9. Ответственность </h3>
                    <p>
                        9.1. В случае нарушения Заказчиком условий Договора, законодательства Российской Федерации, норм морали и нравственности, либо технических требований Сайта, Исполнитель вправе заблокировать доступ в Личный кабинет, запретить либо ограничить доступ Заказчика к определенным или всем функциям Сайта, программам обучения, курсам, бесплатным занятиям.
                    </p>
                    <p>
                        9.2. При выявлении случаев предоставления Заказчиком доступа к Личному кабинету третьим лицам Исполнитель вправе полностью заблокировать доступ Заказчика к Личному кабинету, программам обучения и курсам, в том числе, заранее оплаченным Заказчиком.
                    </p>
                    <p>
                        9.3. Исполнитель не отвечает за работоспособность Сайта и не гарантирует его бесперебойной работы. Исполнитель также не гарантирует сохранности информации, размещенной на Сайте и возможности бесперебойного доступа к программам обучения, курсам и иным материалам.
                    </p>
                    <p>
                        9.4. Заказчик использует Сайт в том виде, в каком он представлен, на собственный риск. Исполнитель не гарантирует Заказчику достижения каких-либо результатов вследствие использования Сайта.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    10. Порядок разрешения Споров </h3>
                    <p>
                        10.1. Все споры, разногласия и претензии, которые могут возникнуть в связи с исполнением, расторжением или признанием недействительным Договора, Стороны будут стремиться решить путем переговоров. Сторона, у которой возникли претензии и/или разногласия, направляет другой Стороне сообщение с указанием возникших претензий и/или разногласий.
                    </p>
                    <p>
                        10.2. В случае если ответ на сообщение не будет получен направившей сообщение Стороной в течение 30 (тридцати) рабочих дней с даты направления соответствующего сообщения, либо если Стороны не придут к соглашению по возникшим претензиям и/или разногласиям, спор подлежит разрешению в судебном порядке по месту нахождения Исполнителя.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    11. Изменение условий Договора </h3>
                    <br>
                    <span id="bx-cursor-node"> </span>
                    <p>
                        11.1 Исполнитель вправе в одностороннем порядке изменять условия Договора, при этом такие изменения вступают в силу по истечении 3 (трех) календарных дней с момента опубликования новой версии Договора.
                    </p>
                    <p>
                        11.2 При каждом последующем посещении Сайта до начала пользования Личным кабинетом Заказчик обязуется знакомиться с новой версией Договора. Продолжение использования Сайта и Личного кабинета будет означать согласие Заказчика с условиями новой версии Договора.
                    </p>
                    <p>
                        11.3 Если Пользователь не согласен с условиями новой версии Договора, он прекращает пользоваться Сайтом.
                    </p>
                    <p>
                        11.4. В том случае, если Заказчик оплатил Услуги и не согласен с новой версией Договора, то он обязан уведомить об этом Исполнителя. В этом случае Заказчик может завершить оплаченные программы обучения. По завершении оплаченных программ обучения Заказчик обязан прекратить пользоваться Сайтом или согласиться с новой версией Договора. При этом продолжение использования Личного кабинета будет означать принятие Заказчиком условий новой версии Договора.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    12. Заключительные положения </h3>
                    <p>
                        12.1. Договор и все возникающие из него правоотношения регулируются законодательством Российской Федерации. Все возникающие споры разрешаются в соответствии с законодательством Российской Федерации.
                    </p>
                    <p>
                        12.2. Признание судом какого-либо положения Договора недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Договора.
                    </p>
                    <p>
                        12.3. Программно-аппаратные ошибки как на стороне Исполнителя, так и на стороне Заказчика, приведшие к невозможности получения Заказчиком доступа к Сайту и/или Учебным курсам, являются обстоятельствами непреодолимой силы, и основанием освобождения от ответственности за неисполнение обязательств Исполнителя по Договору.
                    </p>
                    <p>
                        12.4. Признание недействительным одного или нескольких положений Договора не означает недействительность других положений Договора и не прекращает их действие.
                    </p>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <h3>
                    13. Реквизиты Исполнителя </h3>
                    <p>
                    <span>&nbsp;</span>
                    </p>
                    <p>
                        ООО “ЭЛСИ ДИЗАЙН»
                    </p>
                    <p>
                        ИНН 7706773580 КПП 770601001
                    </p>
                    <p>
                        ОГРН 1127746332713 ОКПО 09256908
                    </p>
                    <p>
                        Адрес: 115280, Москва, ул. Тюфелева Роща д.22, стр.2, пом. 9
                    </p>
                    <p>
                        E-mail: <a href="mailto:info@yaproducer.com">info@yaproducer.com</a>
                    </p>
                    <p>
                        Web: <a href="http://www.yaproducer.com/">www.yaproducer.com</a>
                    </p>

                </article>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "Policy"
}
</script>
<style scoped>
    a {
        color: #000;
        text-decoration: underline;
    }
</style>