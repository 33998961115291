import Http from "@/http";
import Time from "@/helpers/time";

export default {
    state: {
        token: localStorage.getItem('token'),
        expiredAt: +localStorage.getItem('expired_at'),
        currentUser: null
    },
    getters: {
        token(state) {
            return state.token;
        },
        isAuth(state) {
            return state.token !== null;
        },
        isExpired(state) {
            return Time.get() >= state.expiredAt;
        },
        currentUser(state) {
            return state.currentUser;
        },
        rememberUser(state) {
            return localStorage.getItem('remember_user') !== null;
        }
    },
    mutations: {
        setAuth(state, auth) {
            state.token = auth.access_token;
            localStorage.setItem('token', auth.access_token);
            localStorage.setItem('expired_at', Time.get() + +auth.expires_in);
        },
        logout(state) {
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('expired_at');
        },
        setCurrentUser(state, user) {
            state.currentUser = user;
        }
    },
    actions: {
        async login({ commit }, data) {
            let response = await Http.post("/api/v1/auth/login", data);
            commit('setAuth', response.data);
        },
        async me({ commit }) {
            let response = await Http.get("/api/v1/auth/me");
            commit('setCurrentUser', response.data);
        },
        async refresh({ commit, getters, dispatch }) {
            let response = await Http.post("/api/v1/auth/refresh");
            commit('setAuth', response.data);
        },
        async logout({commit}) {
            await Http.post("/api/v1/auth/logout");
            commit('logout');
        }
    }
}