<template>
	<div id="app" class="wrapper">
		<router-view name="header"/>
		<main class="content">
			<router-view name="aside"/>
			<fade-transition origin="center" mode="out-in" :duration="250">
				<router-view />
			</fade-transition>
		</main>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Theme from "@/helpers/theme";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "application",
	components: {
		FadeTransition,
    },
	computed: mapGetters(['currentUser']),
	async mounted() {
		this.updateMeta();
  },
	watch: {
		$route() {
			this.updateMeta();
		}
  },
	methods: {
		updateMeta() {
		  if(this.$route.meta.theme) {
        this.theme =  Theme.updateTheme(this.$route.meta.theme.class);
      }
		},
    async refresh() {
		  if(!this.$store.getters.isAuth) {
		    return;
      }

      if(this.$store.getters.isExpired) {
        if(this.$store.getters.rememberUser) {
          await this.$store.dispatch('refresh');
        } else {
          await this.$store.dispatch('logout');
        }
      }
    }
	},
  async created() {
    await this.refresh();
  },
  async updated() {
    await this.refresh();
  }
};

</script>

<style>
.form-select {
	background: #fff;
	border-radius: 10px;
	width: 100%;
	border: 0;
	padding: 10px;
}
</style>
