<template>
	<div class="page-content">
        <section class="section-select">
            <div class="container">
                <div class="section-top">
                    <h2 class="section-title">Выберите задачу</h2>
                    <p class="section-descr">Что будем продюсировать?</p>
                </div>
                <task-list></task-list>
            </div>
        </section>
	</div>
</template>
<script>
import TaskList from '@/components/TaskList';
export default {
    name: "start",
	components: { TaskList }
}
</script>
