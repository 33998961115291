<template>
  <form @submit.prevent="submit" class="form-login">

    <div class="form-item">
      <input
          v-model="formData.email"
          class="form-input"
          :class="{error: emailValidationError}"
          type="text"
          name="login"
          placeholder="Введите адрес электронной почты"
      />
      <label v-if="emailValidationError" class="error" for="name">{{emailValidationError}}</label>
    </div>

    <div class="form-item">
        <input
            v-model="formData.password"
            class="form-input"
            :class="{error: passwordValidationError}"
            :type="passwordFieldType"
            name="password"
            placeholder="Введите пароль"
        />

        <span 
            title="Показать пароль" 
            @click="showPasswd"
            class="password-control"
            :class="btnClass"
            >
        </span>

      <label v-if="passwordValidationError" class="error" for="name">{{passwordValidationError}}</label>
    </div>

    <div  v-if="showCreatedAlert"  class="alert success-reg">
        Вы успешно зарегистрировались, теперь вы можете авторизоваться
    </div>

    <div v-if="errorMessage" class="credentinals-error">
      {{errorMessage}}
    </div>

    <div class="form-button center">
      <button class="btn btn-lilac button" type="submit">
        Войти
      </button>
    </div>

    <div class="col col-auto">
      <div class="checkbox-item checkbox-item--term">
        <input v-model="rememberMe" type="checkbox" name="check" id="rd1">
        <label for="rd1">
                <span class="check-icon">
                    <svg class="svg-icon icon-correct">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-correct"></use>
                    </svg>
                </span>

          <span class="check-text">
            Запомнить меня
          </span>

        </label>
      </div>

    <loading-spinner v-if="sending"></loading-spinner>

    </div>
  </form>
</template>
<script>
import ErrorFetcher from '@/helpers/error-fetcher';
import { required, email } from 'vuelidate/lib/validators';
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
    name: "form-login",
    data() {
        return {
            sending: false,
            attemptSend: false,
            showCreatedAlert: false,
            errorMessage: false,
            passwordFieldType: 'password',
            formData: {
                email: '',
                password: ''
            },
            btnClass: '',
            rememberMe: false
        }
    },
    validations: {
        formData: {
            email: {
                required,
                email
            },
            password: {
                required,
            }
        }
    },
    components: {
        LoadingSpinner
    },
  computed: {
      emailValidationError() {
          if(this.attemptSend && !this.$v.formData.email.required) {
              return 'Поле обязательно для заполнения';
          }
          if(!this.$v.formData.email.email) {
              return 'Некорректный формат почты';
          }
          return '';
      },
      passwordValidationError() {
          if(this.attemptSend && !this.$v.formData.password.required) {
              return 'Поле обязательно для заполнения';
          }

          return '';
      }
    },
    methods: {
        async submit() {
            if(this.sending) return;
            this.sending = true;
            this.attemptSend = true;
            this.errorMessage = false;

            try {
                if(!this.$v.$invalid) {
                    await this.$store.dispatch("login", this.formData);
                    window.location.href = "/profile";
                    if(this.rememberMe) {
                      localStorage.setItem('remember_user', 'true');
                    }
                }
            } catch (e) {
              this.errorMessage = ErrorFetcher.fetch(e, 'Неверный логин или пароль');
            } finally {
                this.sending = false;
            }
        },
        showPasswd() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            this.btnClass = this.btnClass === 'view' ? '' : 'view';
        }
    },
    mounted() {
        if(localStorage.getItem('createdAlert')) {
            this.showCreatedAlert = true;
            localStorage.removeItem('createdAlert');
        }
    }
};
</script>
<style lang="scss" scoped>
    .spinner-container { padding: 10% 10% 25% }
</style>