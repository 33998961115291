<template>
<div class="page-content">
	<section class="section-main">
		<div class="container">
			<div class="section-top">
				<h2 class="section-title">Создание песни с нуля</h2>
				<p class="section-descr">Что будем продюсировать?</p>
			</div>
			
            <form-song-from-scratch></form-song-from-scratch>

			<div class="section-button">
                <router-link class="btn btn-outline-white" to="/song-create-new">
                    <i class="icon-long-arrow arrow-left"></i>
                    <span>Назад</span>
                </router-link>
            </div>
		</div>
	</section>
</div>
	
</template>
<script>
import FormSongFromScratch from '@/components/Forms/Content/Song/FormSongFromScratch';
export default {
    name: "song-from-scratch",
	components: { FormSongFromScratch }
}
</script>
