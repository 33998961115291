<template>
	<div class="page-content">
        <section class="section-select">
            <div class="container">
                <div class="section-top">
                    <h2 class="section-title">Выберите задачу</h2>
                    <!-- <p class="section-descr">Опции для песни:</p> -->
                </div>

                <task-list></task-list>

                <div class="section-button">
                    <router-link class="btn btn-outline-white" to="/song">
                        <i class="icon-long-arrow arrow-left"></i>
                        <span>Назад</span>
                    </router-link>
                </div>
            </div>
        </section>
	</div>
</template>
<script>
import TaskList from '@/components/TaskList';
export default {
    name: "start",
	components: { TaskList }
}
</script>