<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Регистрация товарного знака в Роспатенте">
         <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">tm</div>
        <div class="form-item">
            <label class="form-label" for="linkCover">Введите название торговой марки</label>
            <input class="form-input" name="linkCover" data-name="trade_mark_name" id="linkCover" placeholder="Название торговой марки" data-msg-required="Заполните поле название торговой марки" required="" v-model="formData.trade_mark_name">
            <p class="field-text">Введите слово которое хотите зарегистрировать</p>
        </div>
        <div class="form-item">
            <label class="form-label" for="file">Ссылка на изображение торговой марки</label>
            <input class="form-input" name="linkCover" data-name="trade_mark_link" id="file" placeholder="Название торговой марки" data-msg-required="Заполните поле название торговой марки" required="" v-model="formData.trade_mark_link">
        </div>
        <div class="form-item">
            <label class="form-label" for="data-trade">Данные о владельце торговой марки</label>
            <input class="form-input" name="data-trade" data-name="trade_mark_owner" id="data-trade" placeholder="Данные о владельце" v-model="formData.trade_mark_owner">
            <p class="field-text">ФИО если физическое лицо, наименование юридического лица или индивидуального предпринимателя</p>
        </div>
        <div class="form-item">
            <label class="form-label" for="data1">ОГРН (ОГРНИП) заявителя</label>
            <input class="form-input" name="data1" data-name="ogrn" id="data1" placeholder="ОГРН или ОГРНИП" data-msg-required="Заполните поле ОГРН (ОГРНИП) заявителя" required="" v-model="formData.ogrn">
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Юридический адрес</label>
            <textarea class="form-input form-textarea" name="text1" data-name="address" id="text1" placeholder="Юридический адрес компании или место нахождения индивидуального предпринимателя" rows="3" data-msg-required="Заполните поле юридический адрес" required="" v-model="formData.address"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label" for="text2">Описание музыкального проекта</label>
            <textarea class="form-input form-textarea" name="text2" data-name="description" id="text2" placeholder="Подробная информация об артисте или группе / проекте в свободной форме." rows="3" data-msg-required="Заполните описание музыкального проекта" required="" v-model="formData.description"></textarea>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "tm",
          trade_mark_name: "",
          trade_mark_link: "",
          trade_mark_owner: "",
          ogrn: "",
          address: "",
          description: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>