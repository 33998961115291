<template>
    <div>
        <div v-if="currentUser" class="tabs-content__item active">
          <form class="form-profile">
            <div class="form-row">
              <div class="col">
                <div class="form-item">
                  <div class="profile-data">
                    Имя: {{ currentUser.name }}
                    <svg class="svg-icon icon-pencil">
                      <use xlink:href="/assets/img/spriteSvg.svg#sprite-pencil"></use>
                    </svg>
                  </div>
                  <input
                    class="form-input"
                    type="text"
                    name="name"
                    placeholder="Алексей"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-item">
                  <div class="profile-data">
                    Email: {{ currentUser.email }}
                    <svg class="svg-icon icon-pencil">
                      <use xlink:href="/assets/img/spriteSvg.svg#sprite-pencil"></use>
                    </svg>
                  </div>
                  <input
                    class="form-input"
                    type="email"
                    name="email"
                    placeholder="supermc@mail.ru"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-item">
                  <div class="profile-data">
                    Фамилия: Иванов
                    <svg class="svg-icon icon-pencil">
                      <use xlink:href="/assets/img/spriteSvg.svg#sprite-pencil"></use>
                    </svg>
                  </div>
                  <input
                    class="form-input"
                    type="text"
                    name="login"
                    placeholder="Иванов"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-item">
                  <div class="profile-data">
                    Телефон: {{ currentUser.phone }}
                    <svg class="svg-icon icon-pencil">
                      <use xlink:href="/assets/img/spriteSvg.svg#sprite-pencil"></use>
                    </svg>
                  </div>
                  <input
                    class="form-input"
                    type="tel"
                    name="phone"
                    placeholder="8999-999-99-99"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <loading-spinner v-else></loading-spinner>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
    name: "ProfileData",
    computed: mapGetters(['currentUser']),
    components: {LoadingSpinner}
}
</script>