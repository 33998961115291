<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <div style="display: none;" class="form_key">smd</div>
        <input type="hidden" name="title" value="Разработать дизайн соцсетей">
        <order-alert v-if="isAuth"></order-alert>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="select">Жанр, стиль, направление</label>
            <div class="select-wrap">
                <select class="form-select" name="select" data-name="song_style" id="select" data-msg-required="Выберите один из вариантов" required="" v-model="formData.song_style">
                    <option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
                    <option value="Рок-музыка">Рок-музыка</option>
                    <option value="Джаз">Джаз</option>
                </select>
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">Для каких соцальных сетей нужен дизайн?</div>
            <div class="checkbox-group" data-name="platforms">
		<platforms ref="platforms"></platforms>
</div>
        </div>
        <div class="form-item" data-name="task_content">
            <label class="form-label" for="text1">Задание дизайнеру</label>
            <textarea v-model="formData.task_content" class="form-input form-textarea" name="text1" id="text1" placeholder="Опишите подробно Ваши пожелания." rows="3" data-msg-required="Заполните поле задание дизайнеру" required=""></textarea>
            <p class="field-text">Опишите подробно Ваши пожелания к дизайну. Наши дизайнеры подготовят для Вас оформление для социальных сетей. После утверждения, Вам на почту будет выслан полноценный SMM-пак с дизайнами для всех указанных Вами социальных сетей в соответствии с их размерами и гайдлайнами.</p>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import Platforms from "@/components/Misc/Platforms";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";

export default {
  data() {
    return {
        formData: {
          form_key: "smd",
          project_name: "",
          song_style: "Хип-хоп, Рэп",
          platforms: "",
          task_content: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    Platforms,
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.formData.platforms = this.$refs.platforms.getSelected();

      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>