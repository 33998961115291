<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Мониторинг">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">pm</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Расскажите о проекте</label>
            <textarea class="form-input form-textarea" name="text1" data-name="project_description" id="text1" placeholder="Подробнее расскажите о Вашем музыкальном проекте" rows="3" data-msg-required="Заполните поле о проекте" required="" v-model="formData.project_description"></textarea>
            <p class="field-text">Подробнее расскажите о Вашем музыкальном проекте и наши специалисты ежедневно будут отслеживать всю информацию о Вас в Интернете. Наши специалисты в оперативном режиме будут уведомлять Вас о выходе той или иной статьи о Вас, комментария, упоминания, голосования и др.</p>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "pm",
          project_name: "",
          project_description: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
   components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>