<template>
 	<div class="page-content">
		<section class="section-main">
			<div class="container">
				<div class="section-top">
					<h2 class="section-title">Купить готовую песню</h2>
					<p class="section-descr">Что будем продюсировать?</p>
				</div>
				
				<form-song-buy></form-song-buy>

				<div class="section-button">
					<router-link class="btn btn-outline-white" to="/song-create">
						<i class="icon-long-arrow arrow-left"></i>
						<span>Назад</span>
					</router-link>
				</div>
			</div>
		</section>
 	</div>
	
</template>
<script>
import FormSongBuy from '@/components/Forms/Content/Song/FormSongBuy';
export default {
    name: "song-buy",
	components: { FormSongBuy }
}
</script>
