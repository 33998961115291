 <template>
<div class="page-content">
	<section class="section-main">
		<div class="container">
			<div class="section-top">
				<h2 class="section-title">Создать Lyric видео</h2>
				<p class="section-descr">Что будем продюсировать?</p>
			</div>
			
            <form-video-lyric></form-video-lyric>

			<div class="section-button">
                <router-link class="btn btn-outline-white" to="/video-create">
                    <i class="icon-long-arrow arrow-left"></i>
                    <span>Назад</span>
                </router-link>
            </div>
		</div>
	</section>
</div>
	
</template>
<script>
import FormVideoLyric from '@/components/Forms/Content/Video/FormVideoLyric';
export default {
    name: "video-lyric",
	components: { FormVideoLyric }
}
</script>
