import Vue from "vue";
import Router from "vue-router";
import LayoutHeader from "./layout/Header";
import LayoutFooter from "./layout/Footer";
import LayoutAside from "./layout/Aside";
import RightSidebar from "./layout/RightSidebar";

// Auth
import Register from "./views/Auth/Register.vue";
import Login from "./views/Auth/Login.vue";
import Restore from "./views/Auth/Restore.vue";
import NewPassword from "./views/Auth/NewPassword.vue";
import Phone from "./views/Auth/Phone.vue";
import Start from "./views/Start.vue";
import Profile from "./views/PersonalArea/Profile.vue";

// Any pages
import MsgOrder from "./views/Content/MsgOrder.vue";
import MsgRestore from "./views/Content/MsgRestore.vue";
import MsgRegister from "./views/Content/MsgRegister.vue";
import Policy from "./views/Content/Policy.vue";
import Legal from "./views/Content/Legal.vue";
import Contacts from "./views/Content/Contacts.vue";
import FAQ from "./views/Content/FAQ.vue";
import Confirm from "@/views/Service/Confirm";
import CheckResetToken from "@/views/Service/CheckResetToken";

// Song
import Song from "./views/Content/Song/Song.vue";
import SongCreate from "./views/Content/Song/SongCreate.vue";
import SongCreateNew from "./views/Content/Song/SongCreateNew.vue";
import SongFromScratch from "./views/Content/Song/SongFromScratch.vue";
import SongFromTemplate from "./views/Content/Song/SongFromTemplate.vue";
import SongBuy from "./views/Content/Song/SongBuy.vue";
import SongPromo from "./views/Content/Song/SongPromo.vue";
import SongRelease from "./views/Content/Song/SongRelease.vue";

// Video
import Video from "./views/Content/Video/Video.vue";
import VideoCreate from "./views/Content/Video/VideoCreate.vue";
import VideoLyric from "./views/Content/Video/VideoLyric.vue";
import VideoClip from "./views/Content/Video/VideoClip.vue";
import VideoPromo from "./views/Content/Video/VideoPromo.vue";

// Artist
import Artist from "./views/Content/Artist/Artist.vue";
import ArtistAccountDesign from "./views/Content/Artist/ArtistAccountDesign.vue";
import ArtistBrandbook from "./views/Content/Artist/ArtistBrandbook.vue";
import ArtistCoverImage from "./views/Content/Artist/ArtistCoverImage.vue";
import ArtistDesign from "./views/Content/Artist/ArtistDesign.vue";
import ArtistDomain from "./views/Content/Artist/ArtistDomain.vue";
import ArtistLogo from "./views/Content/Artist/ArtistLogo.vue";
import ArtistMassMedia from "./views/Content/Artist/ArtistMassMedia.vue";
import ArtistMonitoring from "./views/Content/Artist/ArtistMonitoring.vue";
import ArtistName from "./views/Content/Artist/ArtistName.vue";
import ArtistNaming from "./views/Content/Artist/ArtistNaming.vue";
import ArtistPressRelease from "./views/Content/Artist/ArtistPressRelease.vue";
import ArtistPromo from "./views/Content/Artist/ArtistPromo.vue";
import ArtistRatingUp from "./views/Content/Artist/ArtistRatingUp.vue";
import ArtistReputation from "./views/Content/Artist/ArtistReputation.vue";
import ArtistSocialMedia from "./views/Content/Artist/ArtistSocialMedia.vue";
import ArtistTrademark from "./views/Content/Artist/ArtistTrademark.vue";
import ArtistVote from "./views/Content/Artist/ArtistVote.vue";
import ArtistWebDev from "./views/Content/Artist/ArtistWebDev.vue";
import ArtistWiki from "./views/Content/Artist/ArtistWiki.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: "active",
    routes: [

        { path: '*', redirect: '/' },

        { // Msg order page
            name: "msg-order",
            path: "/order-msg",
            components: {
                header: LayoutHeader,
                default: MsgOrder,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "", class: "page-yellow" },
                page: { name: 'Заказ принят', number: '02' },
                breadcrumbs: [],
                taskList: []
            }
        },
        { // Msg restore page
            name: "msg-restore",
            path: "/restore-msg",
            components: {
                header: LayoutHeader,
                default: MsgRestore,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "", class: "page-yellow" },
                page: { name: 'Восстановление доступа', number: '02' },
                breadcrumbs: [],
                taskList: []
            }
        },
        { // Msg register page
            name: "msg-register",
            path: "/register-msg",
            components: {
                header: LayoutHeader,
                default: MsgRegister,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "", class: "page-yellow" },
                page: { name: 'Завершение регистрации', number: '02' },
                breadcrumbs: [],
                taskList: []
            }
        },
        


        { // Register page
            name: "register",
            path: "/register",
            components: {
                header: LayoutHeader,
                // aside: LayoutAside,
                default: Register,
                footer: LayoutFooter
            },
            meta: {
                guest: true,
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Страница регистрации', number: '02' },
                breadcrumbs: [
                    { name: 'Главная', link: '/' },
                    { name: 'Регистрация', link: '/register' }
                ],
                taskList: []
            }
        },


        { // Login page
            name: "login",
            path: "/login",
            components: {
                header: LayoutHeader,
                // aside: LayoutAside,
                default: Login,
                footer: LayoutFooter
            },
            meta: {
                guest: true,
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Страница входа', number: '02' },
                breadcrumbs: [
                    { name: 'Главная', link: '/' },
                    { name: 'Авторизация', link: '/login' }
                ],
                taskList: []
            }
        },


        { // Restore page
            name: "restore",
            path: "/restore",
            components: {
                header: LayoutHeader,
                // aside: LayoutAside,
                default: Restore,
                footer: LayoutFooter
            },
            meta: {
                guest: true,
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Восстановление пароля', number: '02' },
                breadcrumbs: [
                    { name: 'Главная', link: '/' },
                    { name: 'Восстановление пароля', link: '/login' }
                ],
                taskList: []
            }
        },


        { // New password page
            name: "new-password",
            path: "/new-password",
            components: {
                header: LayoutHeader,
                // aside: LayoutAside,
                default: NewPassword,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Восстановление пароля', number: '02' },
                breadcrumbs: [],
                taskList: []
            }
        },


        { // New password page
            name: "phone",
            path: "/verify-phone",
            components: {
                header: LayoutHeader,
                // aside: LayoutAside,
                default: Phone,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Подтверждение номера телефона', number: '02' },
                breadcrumbs: [],
                taskList: []
            }
        },


        { // Profile page
            name: "Profile",
            path: "/profile",
            components: {
                header: LayoutHeader,
                default: Profile,
                footer: LayoutFooter
            },
            meta: {
                auth: true,
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Профиль', number: '02' },
                breadcrumbs: [

                ],
                taskList: []
            }
        },


        { // Check reset token route
            path: '/reset-password/:token',
            components: {
                default: CheckResetToken
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Главная страница', number: '01' },
                breadcrumbs: [

                ],
                taskList: [

                ]
            }
        },
        {
            path: '/confirm-email/:token',
            components: {
                default: Confirm
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Главная страница', number: '01' },
                breadcrumbs: [

                ],
                taskList: [

                ]
            }
        },

        { // Policy page
            name: "Policy",
            path: "/policy",
            components: {
                header: LayoutHeader,
                aside: RightSidebar,
                default: Policy,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Юридическая информация', number: '01' },
                breadcrumbs: [],
                taskList: []
            }
        },

        { // Legal page
            name: "Legal",
            path: "/legal",
            components: {
                header: LayoutHeader,
                aside: RightSidebar,
                default: Legal,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Юридическая информация', number: '01' },
                breadcrumbs: [],
                taskList: []
            }
        },

        { // Contacts page
            name: "Contacts",
            path: "/contacts",
            components: {
                header: LayoutHeader,
                aside: RightSidebar,
                default: Contacts,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Контактные данные', number: '01' },
                breadcrumbs: [],
                taskList: []
            }
        },

        { // FAQ page
            name: "FAQ",
            path: "/faq",
            components: {
                header: LayoutHeader,
                aside: RightSidebar,
                default: FAQ,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Контактные данные', number: '01' },
                breadcrumbs: [],
                taskList: []
            }
        },

        { // Home page: 01
            name: "start",
            path: "/",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: Start,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Yellow shit!", class: "page-yellow" },
                page: { name: 'Главная страница', number: '01' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' }
                ],
                taskList: [
                    { name: "Песня", char: "П", link: "/song" },
                    { name: "Видео", char: "В", link: "/video" },
                    { name: "Артист", char: "А", link: "/artist" }
                ]
            }
        },
        
        { // Song page: 02
            name: "song",
            path: "/song",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: Song,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Песня', number: '02' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                ],
                taskList: [
                    { name: "Создать", char: "С", link: "/song-create" },
                    { name: "Выпустить", char: "В", link: "/song-release" },
                    { name: "Продвинуть", char: "П", link: "/song-promo" }
                ]
            }
        },
        { // Song create page: 03
            name: "song-create",
            path: "/song-create",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongCreate,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Создать песню', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Создать песню', link: '/song-create' }
                ],
                taskList: [
                    { name: "Создать новую", char: "С", link: "/song-create-new" },
                    { name: "Купить готовую", char: "К", link: "/song-buy" },
                    { name: "Создать обложку", char: "К", link: "/artist-cover-image" }
                ]
            }
        },
        { // Song create new page: 04
            name: "song-create-new",
            path: "/song-create-new",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongCreateNew,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Создать новую песню', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Создать песню', link: '/song-create' },
                    { name: 'Создать новую песню', link: '/song-create-new' }
                ],
                taskList: [
                    { name: "Пишем с нуля", char: "П", link: "/song-from-scratch" },
                    { name: "Есть заготовка", char: "Е", link: "/song-from-template" },
                ]
            }
        },
        { // Song from scratch page: 05
            name: "song-from-sratch",
            path: "/song-from-scratch",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongFromScratch,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Создание песни с нуля', number: '05' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Создать песню', link: '/song-create' },
                    { name: 'Создать новую песню', link: '/song-create-new' },
                    { name: 'Создать песню с нуля', link: '/song-from-scratch' }
                ],
                taskList: []
            }
        },
        { // Song from template: 05
            name: "song-from-template",
            path: "/song-from-template",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongFromTemplate,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Создать песню из демо', number: '05' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Создать песню', link: '/song-create' },
                    { name: 'Создать новую песню', link: '/song-create-new' },
                    { name: 'Создать песню из демо', link: '/song-from-template' }
                ],
                taskList: []
            }
        },
        { // Song from template: 05
            name: "song-buy",
            path: "/song-buy",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongBuy,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Купить готовую', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Создать песню', link: '/song-create' },
                    { name: 'Купить готовую', link: '/song-buy' },
                ],
                taskList: []
            }
        },
        { // Song from template: 03
            name: "song-release",
            path: "/song-release",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongRelease,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Выпустить песню', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Выпустить песню', link: '/song-release' },
                ],
                taskList: []
            }
        },
        { // Song from template: 03
            name: "song-promo",
            path: "/song-promo",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: SongPromo,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Red shit!", class: "page-red" },
                page: { name: 'Создание из демо', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Песня', link: '/song' },
                    { name: 'Продувинуть песню', link: '/song-promo' },
                ],
                taskList: []
            }
        },
        
        { // Video page: 02
            name: "video",
            path: "/video",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: Video,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Wat? Lilac?", class: "page-lilac" },
                page: { name: 'Видео', number: '02' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Видео', link: '/video' },
                ],
                taskList: [
                    { name: "Создать", char: "С", link: "/video-create" },
                    { name: "Продвинуть", char: "П", link: "/video-promo" }
                ]
            }
        },
        { // Video create page: 03
            name: "video-create",
            path: "/video-create",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: VideoCreate,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Wat? Lilac?", class: "page-lilac" },
                page: { name: 'Создать видео', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Видео', link: '/video' },
                    { name: 'Создать видео', link: '/video-create' },
                ],
                taskList: [
                    { name: "Lyric видео", char: "С", link: "/video-lyric" },
                    { name: "Клип", char: "П", link: "/video-clip" }
                ]
            }
        },
        { // Video lyric page: 04
            name: "video-lyric",
            path: "/video-lyric",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: VideoLyric,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Wat? Lilac?", class: "page-lilac" },
                page: { name: 'Создать Lyric видео', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Видео', link: '/video' },
                    { name: 'Создать видео', link: '/video-create' },
                    { name: 'Создать Lyric видео', link: '/video-lyric' },
                ],
                taskList: []
            }
        },
        { // Video clip page: 04
            name: "video-clip",
            path: "/video-clip",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: VideoClip,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Wat? Lilac?", class: "page-lilac" },
                page: { name: 'Создать клип', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Видео', link: '/video' },
                    { name: 'Создать видео', link: '/video-create' },
                    { name: 'Создать клип', link: '/video-clip' },
                ],
                taskList: []
            }
        },
        { // Video promo: 03
            name: "video-promo",
            path: "/video-promo",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: VideoPromo,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Wat? Lilac?", class: "page-lilac" },
                page: { name: 'Продвижение видео', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Видео', link: '/video' },
                    { name: 'Продвижение видео', link: '/video-promo' },
                ],
                taskList: []
            }
        },
        
        { // Artist page: 02
            name: "artist",
            path: "/artist",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: Artist,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Артист', number: '02' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                ],
                taskList: [
                    { name: "Нейминг", char: "Н", link: "/artist-naming" },
                    { name: "Графика и дизайн", char: "Г", link: "/artist-design" },
                    { name: "PR и Promo", char: "P", link: "/artist-promo" }
                ]
            }
        },
        { // Artist naming page: 03
            name: "artist-naming",
            path: "/artist-naming",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistNaming,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Нейминг', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Нейминг', link: '/artist-naming' },
                ],
                taskList: [
                    { name: "Название", char: "Н", link: "/artist-name" },
                    { name: "Торговая марка", char: "Т", link: "/artist-trademark" },
                    { name: "Регистрация доменов", char: "Р", link: "/artist-domain" },
                    { name: "Регистрация социальных сетей", char: "Р", link: "/artist-social-media" }
                ]
            }
        },
        { // Artist name page: 04
            name: "artist-name",
            path: "/artist-name",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistName,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Регистрация названия', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Нейминг', link: '/artist-naming' },
                    { name: 'Регистрация названия', link: '/artist-name' },
                ],
                taskList: []
            }
        },
        { // Artist trademark page: 04
            name: "artist-trademark",
            path: "/artist-trademark",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistTrademark,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Торговая марка', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Нейминг', link: '/artist-naming' },
                    { name: 'Торговая марка', link: '/artist-trademark' },
                ],
                taskList: []
            }
        },
        { // Artist domain page: 04
            name: "artist-domain",
            path: "/artist-domain",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistDomain,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Регистрация доменов', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Нейминг', link: '/artist-naming' },
                    { name: 'Регистрация доменов', link: '/artist-domain' },
                ],
                taskList: []
            }
        },
        { // Artist social media page: 04
            name: "artist-social-media",
            path: "/artist-social-media",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistSocialMedia,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Регистрация социальных сетей', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Нейминг', link: '/artist-naming' },
                    { name: 'Регистрация социальных сетей', link: '/artist-social-media' },
                ],
                taskList: []
            }
        },
        
        { // Artist design page: 03
            name: "artist-design",
            path: "/artist-design",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistDesign,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Графика и дизайн', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                ],
                taskList: [
                    { name: "Логотип", char: "Л", link: "/artist-logo" },
                    { name: "Брендбук", char: "Т", link: "/artist-brandbook" },
                    { name: "Обложка сингла", char: "Р", link: "/artist-cover-image" },
                    { name: "Дизайн социальных сетей", char: "Р", link: "/artist-account-design" },
                    { name: "Разработка сайта", char: "Р", link: "/artist-web-dev" }
                ]
            }
        },
        { // Artist logo page: 04
            name: "artist-logo",
            path: "/artist-logo",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistLogo,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Разработать логотип', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                    { name: 'Разработать логотип', link: '/artist-logo' },
                ],
                taskList: []
            }
        },
        { // Artist brandbook page: 04
            name: "artist-brandbook",
            path: "/artist-brandbook",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistBrandbook,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Разработать брендбук', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                    { name: 'Разработать брендбук', link: '/artist-brandbook' },
                ],
                taskList: []
            }
        },
        { // Artist cover image page: 04
            name: "artist-cover-image",
            path: "/artist-cover-image",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistCoverImage,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Разработать обложку сингла', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                    { name: 'Разработать обложку сингла', link: '/artist-cover-image' },
                ],
                taskList: []
            }
        },
        { // Artist account design page: 04
            name: "artist-account-design",
            path: "/artist-account-design",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistAccountDesign,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Разработать дизайн соцсетей', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                    { name: 'Разработать дизайн соцсетей', link: '/artist-account-design' },
                ],
                taskList: []
            }
        },
        { // Artist web dev page: 04
            name: "artist-web-dev",
            path: "/artist-web-dev",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistWebDev,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Разработать сайт', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'Графика и дизайн', link: '/artist-design' },
                    { name: 'Разработать сайт', link: '/artist-web-dev' },
                ],
                taskList: []
            }
        },

        { // Artist promo page: 03
            name: "artist-promo",
            path: "/artist-promo",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistPromo,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'PR и Promo', number: '03' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                ],
                taskList: [
                    { name: "Голосования", char: "Г", link: "/artist-vote" },
                    { name: "Википедия", char: "В", link: "/artist-wiki" },
                    { name: "Пресс-релиз", char: "П", link: "/artist-press-release" },
                    { name: "СМИ", char: "С", link: "/artist-mass-media" },
                    { name: "Репутация", char: "Р", link: "/artist-reputation" }
                ]
            }
        },
        { // Artist vote page: 04
            name: "artist-vote",
            path: "/artist-vote",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistVote,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Голосования', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Голосования', link: '/artist-vote' },
                ],
                taskList: []
            }
        },
        { // Artist vote page: 04
            name: "artist-wiki",
            path: "/artist-wiki",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistWiki,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Википедия', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Википедия', link: '/artist-wiki' },
                ],
                taskList: []
            }
        },
        { // Artist press release page: 04
            name: "artist-press-release",
            path: "/artist-press-release",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistPressRelease,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Пресс-релиз', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Пресс-релиз', link: '/artist-press-release' },
                ],
                taskList: []
            }
        },
        { // Artist mass media page: 04
            name: "artist-mass-media",
            path: "/artist-mass-media",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistMassMedia,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'СМИ', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'СМИ', link: '/artist-mass-media' },
                ],
                taskList: []
            }
        },
        { // Artist reputation page: 04
            name: "artist-reputation",
            path: "/artist-reputation",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistReputation,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Репутация', number: '04' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Репутация', link: '/artist-reputation' },
                ],
                taskList: [
                    { name: "Мониторинг", char: "М", link: "/artist-monitoring" },
                    { name: "Удаление негатива", char: "У", link: "/artist-rating-up" },
                ]
            }
        },
        { // Artist monitoring page: 05
            name: "artist-monitoring",
            path: "/artist-monitoring",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistMonitoring,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Мониторинг', number: '05' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Репутация', link: '/artist-reputation' },
                    { name: 'Мониторинг', link: '/artist-monitoring' },
                ],
                taskList: []
            }
        },
        { // Artist rating up page: 05
            name: "artist-rating-up",
            path: "/artist-rating-up",
            components: {
                header: LayoutHeader,
                aside: LayoutAside,
                default: ArtistRatingUp,
                footer: LayoutFooter
            },
            meta: {
                theme: { name: "Green shit!", class: "page-green" },
                page: { name: 'Удаление негатива', number: '05' },
                breadcrumbs: [
                    { name: 'Старт', link: '/' },
                    { name: 'Артист', link: '/artist' },
                    { name: 'PR и Promo', link: '/artist-promo' },
                    { name: 'Репутация', link: '/artist-reputation' },
                    { name: 'Удаление негатива', link: '/artist-rating-up' },
                ],
                taskList: []
            }
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.guest) {
        if (localStorage.getItem('token')) {
            next('/profile');
        }
    } else if (to.meta.auth) {
        if (!localStorage.getItem('token')) {
            next('/login');
        }
    }

    next();
});

export default router;