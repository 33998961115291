 <template>
	<form
        @submit.prevent="submit"
		class="form-event"
		action="@"
		method="POST"
	>
		<input type="hidden" name="title" value="Выпустить песню" />
<order-alert v-if="isAuth"></order-alert>

        <div class="form-item">
            <label class="form-label" for="link">Ссылка на песню</label>
            <input v-model="formData.song_src" required data-msg-required="Заполните поле" req class="form-input" type="text" name="link" id="link" placeholder="https://example-song.wave">
            <p class="field-text">Только в формате Wave</p>
        </div>

        <div class="form-row">
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="performer">Исполнитель</label>
                    <input v-model="formData.artist" class="form-input" type="text" name="performer" id="performer" placeholder="Кто поет?" data-msg-required="Заполните поле исполнитель" required>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="titleSong">Название песни</label>
                    <input v-model="formData.song_name" class="form-input" type="text" name="titleSong" id="titleSong" placeholder="Название песни" data-msg-required="Заполните поле название песни" required>
                </div>
            </div>
        </div>

        <div class="form-item">
            <label class="form-label" for="linkCover">Ссылка на обложку JPG</label>
            <input v-model="formData.cover_src" class="form-input" type="text" name="linkCover" id="linkCover" placeholder="https://example-song.jpg" data-msg-required="Заполните поле ссылка на обложку JPG" required>
            <p class="field-text">Только в формате JPG</p>
        </div>

		<div class="form-item">
			<label class="form-label" for="select">Жанр, стиль, направление</label>
			<div class="select-wrap">
				<select
                    v-model="formData.song_style"
					class="form-select"
					name="select"
					id="select"
					data-msg-required="Выберите один из вариантов"
					required
				>
					<option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
					<option value="Рок-музыка">Рок-музыка</option>
					<option value="Джаз">Джаз</option>
				</select>
			</div>
		</div>

        <div class="form-row">
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="author">Авторы музыки</label>
                    <input v-model="formData.song_author" class="form-input" type="text" name="author" id="author" placeholder="ФИО авторов музыки" data-msg-required="Заполните поле авторы музыки" required>
                </div>
            </div>
            <div class="col">
                <div class="form-item">
                    <label class="form-label" for="author2">Авторы слов</label>
                    <input v-model="formData.words_author" class="form-input" type="text" name="author2" id="author2" placeholder="ФИО авторов слов" data-msg-required="Заполните поле авторы слов" required>
                </div>
            </div>
        </div>

        <div class="form-item">
            <label class="form-label" for="language">Язык песни</label>
            <input v-model="formData.language" class="form-input" type="text" name="language" id="language" placeholder="например, русский" data-msg-required="Заполните язык песни" required>
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Текст песни</label>
            <textarea v-model="formData.lyric" class="form-input form-textarea" name="text1" id="text1" placeholder="Например: Припев: Я-Продюсер! Лучший сервис! Я-Продюсер! Удобный сервис! Куплет: Я люблю сервис Я-Продюсер Ты любишь сервис Я-Продюсер Мы любим сервис Я-Продюсер" rows="3" data-msg-required="Заполните поле текст песни" required></textarea>
        </div>

		<order-btn></order-btn>

        <loading-spinner v-show="orderCreating"></loading-spinner>
        
	</form>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
	name: "form-song-release",
    data() {
        return {
            formData: {
                form_key: "rs",
                song_src: "",
                artist: "",
                song_name: "",
                cover_src: "",
                song_style: "Хип-хоп, Рэп",
                song_author: "",
                words_author: "",
                language: "",
                lyric: ""
            }
        }
    },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
 components: {
		LoadingSpinner,
		OrderBtn,
		OrderAlert,
	},
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    }
  },
  mounted() {
    this.$store.commit('resetOrderForm');
  }
};
</script>
