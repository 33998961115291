<template>
  <form @submit.prevent="submit" class="form-login form-login--reg">

    <div class="form-item">
      <input
          v-model="formData.email"
          class="form-input"
          :class="{error: emailValidationError}"
          type="email"
          name="email"
          placeholder="Введите адрес электронной почты"
      />
      <label v-if="emailValidationError" class="error" for="name">{{emailValidationError}}</label>
    </div>

    <div class="form-item">
      <input
          v-model="formData.name"
          class="form-input"
          :class="{error: nameValidationError}"
          type="text"
          name="login"
          placeholder="Введите ваше имя"
      />
      <label v-if="nameValidationError" class="error" for="name">{{nameValidationError}}</label>
    </div>

    <div class="form-item">
      <input
          v-model="formData.password"
          class="form-input"
          :class="{error: passwordValidationError}"
          :type="passwordFieldType"
          name="password"
          placeholder="Создайте пароль"
      />

      <span 
        title="Показать пароль" 
        @click="showPasswd"
        class="password-control"
        :class="btnClass"
        >
      </span>

      <label v-if="passwordValidationError" class="error" for="password">{{passwordValidationError}}</label>
    </div>

    <div v-if="localOrders.length" class="alert success-reg">
      Вы успешно оформили заказ, чтобы продолжить работу, зарегистрируйтесь
    </div>

    <div class="col col-auto">
        <div class="checkbox-item checkbox-item--term">
            <input v-model="acceptRules" type="checkbox" name="check" id="rd1" checked>
            <label for="rd1">
                <span class="check-icon">
                    <svg class="svg-icon icon-correct">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-correct"></use>
                    </svg>
                </span>

                <span class="check-text">
                   Я принимаю <router-link to="/policy" target="_blank"> Пользовательское соглашение</router-link>
                </span>

            </label>

            <label v-if="attemptSend && !$v.acceptRules.checked" class="error">Вы не приняли пользовательское соглашение</label>
        </div>
    </div>

    <div class="form-button center">
      <button class="btn btn-lilac button" type="submit">
        Зарегистрироваться
      </button>
    </div>

    <loading-spinner v-if="creating"></loading-spinner>

  </form>
</template>
<script>
import {mapGetters} from "vuex";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
    name: "form-register",
    data() {
        return {
            attemptSend: false,
            acceptRules: true,
            creating: false,

            passwordFieldType: 'password',

            formData: {
                email: '',
                name: '',
                password: ''
            },

            backendErrors: {
                email: [],
                name: [],
                password: []
            },

            btnClass: '',
        }
    },
    validations: {
        formData: {
            email: {
                required,
                email
            },
            name: {
                required,
                minLength: minLength(2)
            },
            password: {
                required,
                minLength: minLength(8)
            }
        },
        acceptRules: {
            checked: value => value === true
        }
    },
    components: {
        LoadingSpinner
    },
    computed: {
        ...mapGetters(['localOrders']),
        emailValidationError() {
            if(this.backendErrors.email && this.backendErrors.email.length) {
                return this.backendErrors.email[0];
            }
            if(this.attemptSend && !this.$v.formData.email.required) {
                return 'Укажите свою почту';
            }
            if(!this.$v.formData.email.email) {
                return 'Некорректный формат почты';
            }
            return '';
        },
        nameValidationError() {
            if(this.backendErrors.name && this.backendErrors.name.length) {
                return this.backendErrors.name[0];
            }
            if(this.attemptSend && !this.$v.formData.name.required) {
                return 'Укажите свое имя';
            }
            if(this.attemptSend && !this.$v.formData.name.minLength) {
                return 'Минимальный размер имени - 2 символа';
            }

            return '';
        },
        passwordValidationError() {
            if(this.backendErrors.password && this.backendErrors.password.length) {
                return this.backendErrors.password[0];
            }
            if(this.attemptSend && !this.$v.formData.password.required) {
                return 'Поле обязательно для заполнения';
            }
            if(!this.$v.formData.password.minLength) {
                return 'Минимальный размер пароля - 8 символов';
            }

            return '';
        }
    },
    methods: {
        clearBackendErrors() {
            this.backendErrors.name = [];
            this.backendErrors.email = [];
            this.backendErrors.password = [];
        },
        async submit() {
            if(this.creating) return;
            this.creating = true;
            this.attemptSend = true;
            this.clearBackendErrors();

            try {
                if(!this.$v.$invalid) {
                    await this.$store.dispatch('createUser', this.formData);
                    localStorage.setItem('createdAlert', true);
                    localStorage.setItem('user_email', this.formData.email);
                    await this.$router.push('/register-msg');
                }
            } catch (e) {
                this.backendErrors = e.response.data.errors;
            } finally {
                this.creating = false;
            }
        },
        
        showPasswd() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            this.btnClass = this.btnClass === 'view' ? '' : 'view';
        }
    }
};
</script>