<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Разработка сайта">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">song_cover</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.name">
        </div>
        <div class="form-item">
            <label class="form-label" for="select">Жанр, стиль, направление</label>
            <div class="select-wrap">
                <select class="form-select" name="select" data-name="song_style" id="select" data-msg-required="Выберите один из вариантов" required="" v-model="formData.song_style">
                    <option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
                    <option value="Рок-музыка">Рок-музыка</option>
                    <option value="Джаз">Джаз</option>
                </select>
            </div>
        </div>
      <div class="form-item">
        <label class="form-label" for="file">Ссылка на изображение</label>
        <input class="form-input" name="linkCover" data-name="cover_link" id="file" placeholder="Ссылка" data-msg-required="Обязательно" required="" v-model="formData.cover_link">
      </div>
        <div class="form-item">
            <label class="form-label" for="text1">Задание дизайнеру</label>
            <textarea class="form-input form-textarea" name="text1" data-name="task_content" id="text1" placeholder="Опишите подробно Ваши пожелания." rows="3" data-msg-required="Заполните поле задание дизайнеру" required="" v-model="formData.task_content"></textarea>
            <p class="field-text">Опишите подробно Ваши пожелания к обложке сингла. Наши дизайнеры подготовят для Вас 5 вариантов с возможностью доработки до финального результата. После утверждения, Вам на почту будет выслана полноценная обложка сингла размером 3100 на 3100 в форматах JPG + PSD, которая идеально подходит для отгрузки в Apple Music, iTunes и другие сервисы.</p>
        </div>
       <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "song_cover",
          name: "",
          song_style: "Хип-хоп, Рэп",
          cover_link: "",
          task_content: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>