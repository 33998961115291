<template>
  <div>
    <h3>Пожалуйста подождите...</h3>
    <loading-spinner></loading-spinner>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
  name: "Confirm",
  data() {
    return {
      formData: {
        email: '',
        verify_token: ''
      }
    }
  },
  components: {LoadingSpinner},
  async mounted() {
    this.formData.email = this.$route.query.email;
    this.formData.verify_token = this.$route.params.token;

    if(!this.formData.email || !this.formData.verify_token) {
      await this.$router.push('/');
    }

    await this.$store.dispatch('confirmEmail', this.formData);
    await this.$router.push('/login');
  }
}
</script>