<template>
    <div class="form-row align-center space-between">
        <div class="col col-auto">
            <div class="checkbox-item checkbox-item--term">
                
            </div>
        </div>
        <div class="col col-auto">
            <div class="form-button right">
                <button class="btn btn-lilac button" type="submit">
                    Заказать
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'order-btn',
}
</script>