import Http from "@/http";

export default {
    state: {
        attemptPayment: false
    },
    getters: {
        attemptPayment(state) {
            return state.attemptPayment;
        }
    },
    mutations: {
        setAttemptPayment(state, value) {
            state.attemptPayment = value;
        }
    },
    actions: {
        async yandexPayment({commit}, orderId) {
            commit('setAttemptPayment', true);
            let response = await Http.post('/api/v1/payments/yandex', {
                order_id: orderId
            });
            commit('setAttemptPayment', false);

            return response.data;
        }
    }
}