import CodesMessages from '@/http/codes-messages';

export default {
    firstError: response => {
        let errors = response.data.errors;
        let firstKey = Object.keys(errors)[0];

        return errors[firstKey][0];
    },

    fetch(exception, defaultMessage = null) {
        let status = exception.response.status;
        if(CodesMessages.get(status)) {
            return CodesMessages.get(status);
        }
        if(defaultMessage !== null) {
            return defaultMessage;
        }

        return this.firstError(exception.response);
    }
}