<template>
	<form
		@submit.prevent="submit"
		class="form-event"
		action="@" 
		method="POST"
	>
		<input type="hidden" name="title" value="Создать песню с нуля" />

		<order-alert v-if="isAuth"></order-alert>

		<div class="form-item">
			<label class="form-label" for="select">Жанр, стиль, направление</label>
			<div class="select-wrap">
				<select
					v-model="formData.song_style"
					class="form-select"
					name="select"
					id="select"
					data-msg-required="Выберите один из вариантов"
					required
				>
					<option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
					<option value="Рок-музыка">Рок-музыка</option>
					<option value="Джаз">Джаз</option>
				</select>
			</div>

		</div>
		<div class="form-item">
			<label class="form-label" for="text1">Задание автору</label>
			<textarea
				v-model="formData.task_content"
				class="form-input form-textarea"
				name="text1"
				id="text1"
				placeholder="Напишите задание автору"
				rows="3"
				data-msg-required="Заполните поле задание автору"
				required
			></textarea>
			<p class="field-text">
				Опишите подробно Ваши пожелания к песне. Наши проверенные авторы
				подготовят для Вас музыку, напишут слова и создадут демо-запись.
				После утверждения песни, Вам на почту будет выслана демо-запись,
				аранжировка, текст песни, а также мультитрекинг.
			</p>
		</div>

		<order-btn></order-btn>
		

		<loading-spinner v-show="orderCreating"></loading-spinner>
        
	</form>
</template>
<script>
import { mapGetters } from 'vuex';
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
	name: "form-song-from-scratch",
	data() {
		return {
			formData: {
				form_key: 'sfs',
				song_style: 'Хип-хоп, Рэп',
				task_content: ''
			},
		}
	},
	computed: mapGetters(['isAuth', 'orderCreating', 'attemptCreationOrder', 'firstOrderError']),
	components: {
		LoadingSpinner,
		OrderBtn,
		OrderAlert,
	},
	methods: {
		async submit() {
		  this.$store.commit('setAttemptCreationOrder', true);

			await this.$store.dispatch('createOrder', this.formData);

			if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
		}
	},
  mounted() {
	  this.$store.commit('resetOrderForm');
  }
};
</script>
