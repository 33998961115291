<template>
    <div>
        <div>
          <div class="order-list">
            <div 
                v-for="order in orders"
                :key="order.id"
                class="order"
            >
                <order :order="order"></order>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
            <button v-if="ordersPagination.nextPage" @click="loadOrders" class="load-more">Загрузить еще</button>
        </div>
        <loading-spinner v-show="loader"></loading-spinner>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Order from "@/components/Profile/Tabs/Order";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
    name: "Orders",
    data() {
        return {
            loader: false
        }
    },
    computed: mapGetters(['orders', 'ordersPagination']),
    components: {Order, LoadingSpinner},
    methods: {
        async loadOrders() {
            if(this.loader) return;

            this.loader = true;
            await this.$store.dispatch('fetchOrders');
            this.loader = false;
        }
    },
    async mounted() {
        this.$store.commit('clearOrders');
        this.loadOrders();
    }
}
</script>

<style>
.order {
    background: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    margin-bottom: 10px;
}
.load-more {
  background: #cc00ff;
  margin-right: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
  border: 0;
  padding: 10px;
}
</style>