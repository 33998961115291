<template>
  <div>
    <div v-for="platform in platforms" :key="platform.name" class="checkbox-item">
      <input @input="input($event)" type="checkbox" name="checkbox" :value="platform.name" :id="platform.name" />
      <label :for="platform.name"
      ><span class="check-icon"></span
      ><span class="check-text">{{platform.name}}</span
      ><span class="question-icon" data-tippy-content="текст с описанием услуги">
          <svg class="svg-icon icon-question">
            <use xlink:href="/assets/img/spriteSvg.svg#sprite-question"></use></svg></span
      ></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Platforms",
  data() {
    return {
      selected: [],
      platforms: [
        { name: "YouTube / YouTube Music" },
        { name: "Вконтакте" },
        { name: "Одноклассники" },
        { name: "Instagram" },
        { name: "TikTok" },
        { name: "Публикации в СМИ" },
        { name: "Shazam" },
        { name: "iTunes" },
      ],
    };
  },
  methods: {
    input(event) {
      let value = event.target.defaultValue;

      this.platforms.forEach(platform => {
        if(platform.name === value) {
          if(this.selected.includes(value)) {
            this.selected.splice(this.selected.indexOf(value), 1);
          } else {
            this.selected.push(value);
          }
        }
      });
    },
    getSelected() {
      return this.selected.join(',');
    }
  }
};
</script>
