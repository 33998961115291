<template>
   <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h1 class="section-title">Контакты</h1></div>
                <div class="support-block">
                    <ul class="support-list">
                        <li class="support-list__item">
                            <a class="support-link" href="tel:89253539996">
                                <svg class="svg-icon icon-smartphone">
                                    <use xlink:href="@/assets/img/spriteSvg.svg#sprite-smartphone"></use>
                                </svg>8 925 353 99 96</a>
                        </li>
                        <li class="support-list__item">
                           <div>ООО «ЭЛСИ ДИЗАЙН»</div>
                           <div>ИНН 7706773580 ОГРН 1127746332713</div>
                           <div>Адрес: 115280, Москва, ул. Тюфелева Роща д.22, стр.2, пом. 9</div>
                        </li>
                    </ul>
                </div>
                <div class="social-block">
                    <ul class="social-buttons">
                        <li class="social-buttons__item">
                            <a class="social-link button" href="https://vk.com/yaproducer" target="_blank">
                                <svg class="svg-icon icon-vk">
                                    <use xlink:href="@/assets/img/spriteSvg.svg#sprite-vk"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="social-buttons__item">
                            <a class="social-link button" href="https://www.facebook.com/yaproducer" target="_blank">
                                <svg class="svg-icon icon-facebook">
                                    <use xlink:href="@/assets/img/spriteSvg.svg#sprite-facebook"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="social-buttons__item">
                            <a class="social-link button" href="https://instagram.com/ya.producer" target="_blank">
                                <svg class="svg-icon icon-instagram">
                                    <use xlink:href="@/assets/img/spriteSvg.svg#sprite-instagram"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="social-buttons__item">
                            <a class="social-link button" href="https://t.me/yaproducerbot" target="_blank">
                                <img src="@/assets/img/telegram-app.svg" class="svg-icon icon-instagram icon-telegram">
                                   
                            </a>
                        </li>
                    </ul>
                </div>
                <form class="form-event form-validate cmxform" action="/order.php" method="post">
                    <input type="hidden" name="title" value="Форма обратной связи">
                    <div class="form-title">
                        <h2 class="section-title">Обратная связь</h2></div>
                    <div class="form-item">
                        <input class="form-input" name="contact" id="contact" placeholder="Ваш телефон или Email" required data-msg-required="Заполните поле">
                    </div>
                    <div class="form-item">
                        <textarea class="form-input form-textarea" name="contact-text" id="contact-text" placeholder="Текст сообщения" rows="5" data-msg-required="Заполните поле" required></textarea>
                    </div>
                    <div class="form-button">
                        <button class="btn btn-lilac button" type="submit">Отправить</button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "Contacts"
}
</script>