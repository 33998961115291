<template>
    <div class="page-content pb-0">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h1 class="section-title">Помощь</h1>
                    <p class="section-descr">Найдите свой вопрос в списке, либо <router-link target="_blank" to="/contacts">свяжитесь с нами</router-link></p>
                </div>
                <div class="faq-block">
                    <div class="faq-list">
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                        <div class="faq-list__item">
                            <div class="faq-card">
                                <div class="faq-card__title">Как скоро я увижу результат?</div>
                                <div class="faq-card__text">Взаимодействие корпорации и клиента, суммируя приведенные примеры, концентрирует конвергентный нишевый проект.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "FAQ"
}
</script>