<template>
	<form @submit.prevent="submit" class="form-event" method="POST">
        <input type="hidden" name="title" value="Создать видео клип">
        <order-alert v-if="isAuth"></order-alert>
        <div class="form-item">
            <label class="form-label" for="link">Ссылка на песню</label>
            <input v-model="formData.song_link" class="form-input" name="link" id="link" placeholder="https://example-song" data-msg-required="Заполните поле ссылка на песню" required>
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Задание режиссеру</label>
            <textarea v-model="formData.task_content" class="form-input form-textarea" name="text1" id="text1" placeholder="Опишите подробно Ваши пожелания." rows="3" data-msg-required="Заполните поле задание дизайнеру" required></textarea>
        </div>
        <order-btn></order-btn>

    <loading-spinner v-show="orderCreating"></loading-spinner>

    </form>
</template>
<script>
import {mapGetters} from "vuex";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
	name: "form-video-promo",
  data() {
	  return {
	    acceptRules: false,
      formData: {
	      form_key: 'mv',
        song_link: '',
        task_content: ''
      }
    }
  },
  validations: {
    acceptRules: {
      checked: value => value === true
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    }
  },
  mounted() {
    this.$store.commit('resetOrderForm');
  }
};
</script>