<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Сделать публикации в СМИ">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">m</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="link">Заголовок новости</label>
            <input class="form-input" name="link" data-name="title_news" id="link" placeholder="Напишите заголовок новости" data-msg-required="Заполните поле заголовок новости" required="" v-model="formData.title_news">
            <p class="field-text">Заголовок НЕ должен состоять из ЗАГЛАВНЫХ БУКВ. Заголовок должен содержать минимум 4 и максимум 12 слов. В конце заголовка не ставится точка. Также надо избегать восклицательных и</p>
        </div>
        <div class="form-item">
            <label class="form-label" for="link2">Ссылка на иллюстрацию к новости</label>
            <input class="form-input" name="link2" data-name="illustration_link" id="link2" placeholder="Ссылка на иллюстрацию к новости" v-model="formData.illustration_link">
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Введите текст пресс-релиза</label>
            <textarea class="form-input form-textarea" name="text1" data-name="press_release" id="text1" placeholder="Подробно опишите Вашу новость или идею и изложите факты." rows="3" data-msg-required="Заполните поле текст пресс-релиз" required="" v-model="formData.press_release"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label" for="text2">Пожелания к заказу</label>
            <textarea class="form-input form-textarea" name="text2" data-name="task_content" id="text2" placeholder="Подробно опишите Ваши пожелания." rows="3" v-model="formData.task_content"></textarea>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert"
export default {
  data() {
    return {
        formData: {
          form_key: "m",
          project_name: "",
          title_news: "",
          illustration_link: "",
          press_release: "",
          task_content: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>