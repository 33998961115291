<template> 
    <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h2 class="section-title">Википедия</h2>
                    <p class="section-descr"></p>
                </div>
                
                <form-artist-wiki></form-artist-wiki>

                <div class="section-button">
                    <router-link class="btn btn-outline-white" to="/artist-promo">
                        <i class="icon-long-arrow arrow-left"></i>
                        <span>Назад</span>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import FormArtistWiki from '@/components/Forms/Content/Artist/FormArtistWiki';
export default {
    name: "artist-wiki",
	components: { FormArtistWiki }
}
</script>
