<template> 
    <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h2 class="section-title">Разработать дизайн соцсетей</h2>
                    <p class="section-descr"></p>
                </div>
                
                <form-artist-account-design></form-artist-account-design>

                <div class="section-button">
                    <router-link class="btn btn-outline-white" to="/artist-design">
                        <i class="icon-long-arrow arrow-left"></i>
                        <span>Назад</span>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import FormArtistAccountDesign from '@/components/Forms/Content/Artist/FormArtistAccountDesign';
export default {
    name: "artist-account-design",
	components: { FormArtistAccountDesign }
}
</script>
