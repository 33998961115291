<template> 
<div class="page-content">
	<section class="section-main">
		<div class="container">
			<div class="section-top">
				<h2 class="section-title">Создать артиста</h2>
				<p class="section-descr">Регистрация названия:</p>
			</div>
			
            <form-artist-name></form-artist-name>

			<div class="section-button">
                <router-link class="btn btn-outline-white" to="/artist-naming">
                    <i class="icon-long-arrow arrow-left"></i>
                    <span>Назад</span>
                </router-link>
            </div>
		</div>
	</section>
</div>
	
</template>
<script>
import FormArtistName from '@/components/Forms/Content/Artist/FormArtistName';
export default {
    name: "artist-name",
	components: { FormArtistName }
}
</script>
