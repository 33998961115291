<template>
    <div class="page-number" :title="page.name">

        {{ page.number }}

    </div>
</template>
<script>
    export default {
        name: 'page-number',
        data() {
            return { 
                page: {}
            }
        },
        mounted() { 
            this.updateMeta() 
        },
        watch: { 
            '$route'() { 
                this.updateMeta() 
            } 
        },
        methods: {
            updateMeta() {
               this.page = this.$route.meta.page;
            }
        }
    }
</script>