<template>
  <div style="padding: 10px;">
    <h3>Пожалуйста подождите...</h3>
    <loading-spinner></loading-spinner>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
  name: "CheckResetToken",
  data() {
    return {
      formData: {
        email: '',
        reset_password_token: ''
      }
    }
  },
  components: {LoadingSpinner},
  async mounted() {
    this.formData.email = localStorage.getItem('reset_email');
    this.formData.reset_password_token = this.$route.params.token;

    try {
      await this.$store.dispatch('resetPasswordStepTwo', this.formData);
      localStorage.setItem('reset_password_token', this.formData.reset_password_token);
      window.location.href = '/new-password';
    } catch(e) {
      window.location.href = '/';
    }
  }
}
</script>

<style scoped>

</style>