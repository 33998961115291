<template>
    <nav class="breadcrumbs">
        <div class="vertical-line"></div>
        <ul class="breadcrumbs__list">
            <li
                v-for="(breadcrumbs, item) in breadcrumbsList"
                :key="item"
                :class="{'nav-item': !!breadcrumbs.name}" 
            >
                <router-link
                    @click="routeTo(level)"
                    :to="breadcrumbs.link"
                    :class="{'nav-link': !!breadcrumbs.link}"
                >

                    {{ breadcrumbs.name }}
                    
                </router-link>
            </li>
        </ul>
    </nav>
</template>
<script>
    export default {
        name: 'breadcrumbs',
        data() {
            return {
                breadcrumbsList: []
            }
        },
        mounted() { 
            this.updateMeta() 
        },
        watch: { 
            '$route'() { 
                this.updateMeta() 
            } 
        },
        methods: {
            routeTo (pRouteTo) {
                if (this.breadcrumbsList[pRouteTo].link) this.$router.push(this.breadcrumbsList[pRouteTo].link);
            },
            updateMeta() { 
                this.breadcrumbsList = this.$route.meta.breadcrumbs;
            }
        }
    }
</script>