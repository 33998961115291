 <template>
    <form @submit.prevent="submit" class="form-login">

        <div class="message-block _custom">
            <h1 class="message-block__title">Восстановление доступа</h1>

            <p class="message-block__text">
                Придумайте новый пароль. Минимальная длина пароля: <strong>8</strong> символов
            </p>
        </div>
        
        <div class="form-item">
            <input
                v-model="formData.password"
                class="form-input"
                :class="{error: passwordValidationError}"
                type="password"
                name="password"
                placeholder="Введите новый пароль"
            >
            <label v-if="passwordValidationError" class="error" for="password">{{passwordValidationError}}</label>
        </div>

        <div class="form-item">
            <input
                v-model="formData.password_confirmation"
                class="form-input"
                :class="{error: confirmationError}"
                type="password"
                name="password"
                placeholder="Повторите пароль"
            >
            <label v-if="confirmationError" class="error" for="password">{{confirmationError}}</label>
        </div>

        <div class="form-button center">
            <button class="btn btn-lilac button" type="submit">Отправить</button>
        </div>

        <loading-spinner v-if="sending"></loading-spinner>

    </form>
</template>
<script>
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  name: "form-new-password",
  data() {
    return {
      sending: false,
      attemptSend: false,
      formData: {
        email: '',
        password: '',
        password_confirmation: '',
        reset_password_token: ''
      },
      backendErrors: {
        password: []
      }
    }
  },
  validations: {
    formData: {
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAs: sameAs("password")
      }
    }
  },
  components: {
    LoadingSpinner
  },
  computed: {
    passwordValidationError() {
      if(this.backendErrors.password && this.backendErrors.password.length) {
        return this.backendErrors.password[0];
      }
      if(this.attemptSend && !this.$v.formData.password.required) {
        return 'Поле обязательно для заполнения';
      }
      if(!this.$v.formData.password.minLength) {
        return 'Минимальный размер пароля - 8 символов';
      }

      return '';
    },
    confirmationError() {
      if(this.attemptSend && !this.$v.formData.password_confirmation.required) {
        return 'Поле обязательно для заполнения';
      }
      if(!this.$v.formData.password_confirmation.sameAs) {
        return 'Пароли несовпадают';
      }

      return '';
    }
  },
  methods: {
    async submit() {
      if(this.sending) return;
      this.sending = true;
      this.attemptSend = true;

      try {
        if(!this.$v.$invalid) {
          await this.$store.dispatch('resetPasswordStepThree', this.formData);
          localStorage.removeItem('email');
          localStorage.removeItem('token');
          localStorage.removeItem('reset_email');
          localStorage.removeItem('reset_password_token');
          window.location.href = '/login';
        }
      } catch (e) {
        this.backendErrors = e.response.data.errors;
      } finally {
        this.sending = false;
      }
    }
  },
  mounted() {
    this.formData.email = localStorage.getItem('reset_email');
    this.formData.reset_password_token = localStorage.getItem('reset_password_token');
  }
};
</script>