<template>
    <div class="user-box">
        <div class="user-box__avatar">
            <img
                class="img-fluid"
                src="@/assets/img/images/users.svg"
                width="65"
                height="65"
                alt="avatar"
            />
        </div>
        <div class="user-box__name">
            <span class="user-name">
                <span class="js-shift"><router-link v-if="currentUser" to="/profile">{{currentUser.name}}</router-link></span>
                

                <a class="btn-logout" @click.prevent="logout">
                    <svg class="svg-icon icon-logout ">
                        <use xlink:href="@/assets/img/spriteSvg.svg#sprite-logout"></use>
                    </svg>
                </a>
            </span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: "user-box",
    computed: mapGetters(['currentUser']),
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            window.location.href = '/login';
        }
    },
    async mounted() {
        if(!this.currentUser) {
            await this.$store.dispatch('me');
        }
    }
}
</script>