import Http from "@/http";

export default {
    actions: {
        async resetPasswordStepOne({}, formData) {
            await Http.post('/api/v1/reset-password/step-one', formData);
        },
        async resetPasswordStepTwo({}, formData) {
            await Http.post('/api/v1/reset-password/step-two', formData);
        },
        async resetPasswordStepThree({}, formData) {
            await Http.post('/api/v1/reset-password/step-three', formData);
        },
    }
}