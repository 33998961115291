<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Удаление негатива">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">rn</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Ссылки на негативную информацию о вас</label>
            <textarea class="form-input form-textarea" name="text1" data-name="negative_link" id="text1" placeholder="Ссылки на негативную информацию" rows="2" data-msg-required="Заполните поле ссылки" required="" v-model="formData.negative_link"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label" for="text2">Расскажите о проблеме</label>
            <textarea class="form-input form-textarea" name="text2" data-name="problem" id="text2" placeholder="Подробнее расскажите о проблеме" rows="3" data-msg-required="Заполните поле расскажите о проблеме" required="" v-model="formData.problem"></textarea>
            <p class="field-text">Подробнее расскажите о проблеме - где, когда и на каких сайтах появилась информация, порочащая Вашу репутацию или информация, которая мешает Вам осуществлять свою деятельность. Наши специалисты займутся решением данной проблемы.</p>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "rn",
          project_name: "",
          negative_link: "",
          problem: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
    components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>