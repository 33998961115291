<template>
    <div class="right-sidebar">
        <div class="bg-circle"></div>
        <div class="logo-wrap logo-wrap--sidebar">
            <div class="logo">
                <img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="320" height="397">
            </div>
        </div>
		<page-number></page-number>
		<breadcrumbs></breadcrumbs>
	</div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import PageNumber from '@/components/Misc/PageNumber';
export default {
	name: "layout-aside",
	components: {
		Breadcrumbs,
		PageNumber
	}
}
</script>

               