<template>
    <form class="form-event" action="@" method="@" @submit.prevent="submit">
        <input type="hidden" name="title" value="Голосования и рейтинги">
        <order-alert v-if="isAuth"></order-alert>
        <div style="display: none" class="form_key">v</div>
        <div class="form-item">
            <label class="form-label" for="nameProject">Наименование вашего музыкального проекта</label>
            <input class="form-input" name="nameProject" data-name="project_name" id="nameProject" placeholder="Наименование проекта" data-msg-required="Заполните поле наименование проекта" required="" v-model="formData.project_name">
        </div>
        <div class="form-item">
            <label class="form-label" for="link">Ссылка на голосование</label>
            <input class="form-input" name="link" data-name="vote_link" id="link" placeholder="https://linkvoting" data-msg-required="Заполните поле ссылка на голосование" required="" v-model="formData.vote_link">
        </div>
        <div class="form-item">
            <label class="form-label" for="text1">Задача отделу продвижения</label>
            <textarea class="form-input form-textarea" name="text1" data-name="task_content" id="text1" placeholder="Подробно расскажите о сроках и условиях голосования и каких результатов Вы хотите добиться." rows="3" data-msg-required="Заполните поле задача отделу продвижения" required="" v-model="formData.task_content"></textarea>
        </div>
        <order-btn></order-btn>
    </form>
</template>
<script>
import {mapGetters} from "vuex"
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
  data() {
    return {
        formData: {
          form_key: "v",
          project_name: "",
          vote_link: "",
          task_content: ""
        }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
  }
  },
  mounted() {
    this.$store.commit("resetOrderForm")
  }
}
</script>