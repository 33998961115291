<template>
  <div>
    <div class="profile-tabs-nav tabs-nav">
      <ul class="tabs-nav__list">
        <li 
          v-for="tab in tabs" 
          :key="tab.name"
          class="tabs-nav__item"
          :class="{'active-tab': tab.active}"
          style="cursor: pointer;"
        >
          <a @click="changeTab(tab)" class="tab-item">{{tab.name}}</a>
        </li>
      </ul>
    </div>
    <div class="center-block__bottom">
      <div>
        <component v-bind:is="currentComponent"/>
      </div>
    </div>
  </div>
</template>

<script>
import Orders from "@/components/Profile/Tabs/Orders";
import ProfileData from "@/components/Profile/Tabs/ProfileData";

export default {
  name: "TabContainer",
  data() {
    return {
      tabs: [
        {name: 'Мой профиль', active: true, component: 'ProfileData'},
        {name: 'Мои заказы', active: false, component: 'Orders'}
      ]
    };
  },
  computed: {
    currentComponent() {
      let component = '';
      
      this.tabs.forEach(tab => {
        if(tab.active) component = tab.component;
      });

      return component;
    }
  },
  components: {Orders, ProfileData},
  methods: {
    changeTab(tab) {
      if(tab.active) return;
      
      this.tabs.forEach(tab => tab.active = false);
      tab.active = true;
    }
  }
}
</script>

<style>
.active-tab {
  background: #cc00ff;
  margin-right: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
}
</style>