import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store';
import 'nprogress/nprogress.css';
import './registerServiceWorker';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import Argon from "./plugins/argon-kit";
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(Vuelidate);
Vue.component('v-select', vSelect);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");