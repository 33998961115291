<template> 
    <div class="page-content">
        <section class="section-main">
            <div class="container">
                <div class="section-top">
                    <h2 class="section-title">Мониторинг</h2>
                    <p class="section-descr">Управление репутацией:</p>
                </div>
                
                <form-artist-monitoring></form-artist-monitoring>

                <div class="section-button">
                    <router-link class="btn btn-outline-white" to="/artist-reputation">
                        <i class="icon-long-arrow arrow-left"></i>
                        <span>Назад</span>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import FormArtistMonitoring from '@/components/Forms/Content/Artist/FormArtistMonitoring';
export default {
    name: "artist-monitoring",
	components: { FormArtistMonitoring }
}
</script>
