import Http from "@/http";

export default {
    state: {
        orders: [],
        ordersPagination: {
            baseUrl: '/api/v1/user-orders',
            nextPage: '/api/v1/user-orders'
        }
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        ordersPagination(state) {
            return state.ordersPagination;
        }
    },
    mutations: {
        addOrders(state, orders) {
            state.orders = state.orders.concat(orders);
        },
        setOrdersNextPage(state, page) {
            state.ordersPagination.nextPage = page;
        },
        clearOrders(state) {
            state.orders = [];
            state.ordersPagination.nextPage = state.ordersPagination.baseUrl;
        }
    },
    actions: {
        async fetchOrders({ getters, commit }) {
            let response = await Http.get(getters.ordersPagination.nextPage);
            commit('addOrders', response.data.data);
            commit('setOrdersNextPage', response.data.next_page_url);
        },
    }
}