<template>
	<form @submit.prevent="submit" class="form-event">
		<input type="hidden" name="title" value="Продвинуть песню" />
<order-alert v-if="isAuth"></order-alert>
		<div class="form-item">
			<label class="form-label" for="link">Ссылка на песню</label>
			<input
        v-model="formData.song_link"
				class="form-input"
				type="text"
				name="link"
				id="link"
				placeholder="https://example-song"
				data-msg-required="Заполните поле ссылка на видео"
				required
			/>
		</div>

		<div class="form-item">
			<label class="form-label" for="select"
				>Жанр, стиль, направление</label
			>
			<div class="select-wrap">
				<select
          v-model="formData.song_style"
					class="form-select"
					name="select"
					id="select"
					data-msg-required="Выберите один из вариантов"
					required
				>
					<option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
					<option value="Рок-музыка">Рок-музыка</option>
					<option value="Джаз">Джаз</option>
				</select>
			</div>
		</div>

		<div class="form-item">
			<div class="form-label">Где продвигать?</div>
      <div class="checkbox-group">
        <Platforms ref="platforms" />
      </div>
    </div>

		<div class="form-item">
			<label class="form-label" for="linkCover"
				>Ссылка на обложку JPG</label
			>
			<input
        v-model="formData.song_cover_link"
				class="form-input"
				type="text"
				name="linkCover"
				id="linkCover"
				placeholder="https://example-song.jpg"
				data-msg-required="Заполните поле ссылка на обложку JPG"
				required
			/>
			<p class="field-text">Только в формате JPG</p>
		</div>

		<div class="form-item">
			<label class="form-label" for="text1">Аудитория</label>
			<textarea
        v-model="formData.audience"
				class="form-input form-textarea"
				name="text1"
				id="text1"
				placeholder="Опишите свою аудиторию"
				rows="3"
				data-msg-required="Заполните поле Аудитория"
				required
			></textarea>
			<p class="field-text">
				Подробно опишите свою потенциальную целевую аудиторию.
				<b>Например:</b> страна Россия, женщины, от 20 до 35 лет,
				домохозяйки, много смотрят ТВ, любят песни в жанре поп-музыки
			</p>
		</div>
		<div class="form-item">
			<label class="form-label" for="text2">Описание к песне</label>
			<textarea
        v-model="formData.song_description"
				class="form-input form-textarea"
				name="text2"
				id="text2"
				placeholder="Опишите свою песню"
				rows="3"
				data-msg-required="Заполните поле Аудитория"
				required
			></textarea>
			<p class="field-text">
				Подробное описание к песне в свободной форме (пресс-релиз,
				авторы музыки и слов, слова благодарности или любой другой
				текст, который Вы бы хотели, чтобы слушатели увидели)
			</p>
		</div>

		<order-btn></order-btn>
	</form>
</template>
<script>
import {mapGetters} from "vuex";
import Platforms from "@/components/Misc/Platforms";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";

export default {
	name: "form-song-promo",
  data() {
    return {
      formData: {
        form_key: 'sp',
        song_link: '',
        song_style: 'Хип-хоп, Рэп',
        song_cover_link: '',
        audience: '',
        song_description: '',
        platforms: ''
      }
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
		LoadingSpinner,
		OrderBtn,
		OrderAlert,
	},
  methods: {
    async submit() {
      this.formData.platforms = this.$refs.platforms.getSelected();

      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    }
  },
  mounted() {
    this.$store.commit('resetOrderForm');
  }
};
</script>
