import { render, staticRenderFns } from "./VideoLyric.vue?vue&type=template&id=08d05c90&"
import script from "./VideoLyric.vue?vue&type=script&lang=js&"
export * from "./VideoLyric.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports