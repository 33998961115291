<template>
	<div class="main-content">
		<div class="center-block center-block--form">
			<div class="logo-wrap logo-wrap--top">
				<div class="logo">
					<img
						class="img-fluid"
						src="@/assets/img/logo.svg"
						alt="logo"
						width="155"
						height="193"
					/>
				</div>
			</div>
            
			<form-login></form-login>

			<div class="center-block__bottom">
				<div class="row space-between">
					<div class="col">
                        <router-link class="link" to="/register">Регистрация</router-link>
					</div>
					<div class="col">
						<router-link class="link" to="/restore">Забыли пароль?</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FormLogin from "@/components/Forms/Auth/FormLogin";
export default {
	name: "login",
	components: { FormLogin }
};
</script>
