<template>
	<div class="main-content">
		<div class="center-block center-block--form">
			<div class="logo-wrap logo-wrap--top">
				<div class="logo">
					<img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193"/>
				</div>
			</div>
            
			<form-restore></form-restore>

		</div>
	</div>
</template>
<script>
import FormRestore from "@/components/Forms/Auth/FormRestore";
export default {
	name: "restore",
	components: { FormRestore }
};
</script>
