<template>
    <div class="select-list">
        <div
            v-for="(taskList, item) in taskListItems"
            :key="item"
            :class="{'select-list__item': !!taskList.name}" 
        >
            <router-link
                @click="routeTo(item)"
                :to="taskList.link"
                :class="{'select-link': !!taskList.link}"
            >
                <div class="select-link__letter mask-char">
                    <svg class="mask-char-container">
                        <rect class="mask-char-bg" :mask="'url(#mask-char-' + item + ')'" width="100%" height="100%" x="0" y="0"></rect>
                        <mask :id="'mask-char-' + item">
                            <rect width="100%" height="100%" x="0" y="0"></rect>
                            <text x="50%" y="100" text-anchor="middle">
                                
                                {{ taskList.char }}
                                
                            </text>
                        </mask>
                    </svg>
                </div>
                <div class="select-link__text">
                    
                    {{ taskList.name }}
                    
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "task-list",
    data() {
        return {
            taskListItems: []
        }
    },
    mounted() { 
        this.updateMeta() 
    },
    watch: { 
        '$route'() { 
            this.updateMeta() 
        } 
    },
    methods: {
        routeTo (pRouteTo) {
            if (this.taskListItems[pRouteTo].link) this.$router.push(this.taskListItems[pRouteTo].link);
        },
        updateMeta() { 
            this.taskListItems = this.$route.meta.taskList;
        }
    }
};
</script>
