 <template>
    <form @submit.prevent="submit" class="form-login">
        
        <div class="form-item">
            <input
                v-model="formData.email"
                class="form-input"
                type="email"
                name="email"
                placeholder="Введите адрес электронной почты"
            >
            <label v-if="emailValidationError" class="error" for="name">{{emailValidationError}}</label>
        </div>

        <div class="form-button center">
            <button class="btn btn-lilac button" type="submit">Воcстановить</button>
        </div>

        <loading-spinner v-if="sending"></loading-spinner>

    </form>
</template>
<script>
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: "form-restore",
  data() {
    return {
      sending: false,
      attemptSend: false,
      formData: {
        email: ''
      },
      backendErrors: {
        email: []
      }
    }
  },
  validations: {
    formData: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    emailValidationError() {
      if(this.backendErrors.email && this.backendErrors.email.length) {
        return this.backendErrors.email[0];
      }
      if(this.attemptSend && !this.$v.formData.email.required) {
        return 'Укажите свою почту';
      }
      if(!this.$v.formData.email.email) {
        return 'Некорректный формат почты';
      }
      return '';
    }
  },
  components: {
    LoadingSpinner
  },
  methods: {
    async submit() {
      if(this.sending) return;
      this.sending = true;
      this.attemptSend = true;

      try {
        if(!this.$v.$invalid) {
          await this.$store.dispatch('resetPasswordStepOne', this.formData);
          localStorage.setItem('reset_email', this.formData.email);
          window.location.href = '/restore-msg';
        }
      } catch (e) {
        this.backendErrors = e.response.data.errors;
      } finally {
        this.sending = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
    .spinner-container { padding: 10% 10% 25% }
</style>