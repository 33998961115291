<template>
    
    <div class="alert alert-msg alert-msg--order" v-if="currentUser.phone_verified_at == null">
        <div class="alert-msg__title">Номер телефона не подтвержден. <router-link class="alert-marker" to="/verify-phone">Подтвердить</router-link></div>
        * Это нужно для того, чтобы наш менеджер связался с вами и уточнил детали заказа
    </div>

</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'order-alert',
    computed: mapGetters(['currentUser']),
}
</script>