<template>
	<div class="main-content">
		<div class="center-block center-block--form">
			<div class="logo-wrap logo-wrap--top">
				<div class="logo">
					<img class="img-fluid" src="@/assets/img/logo.svg" alt="logo" width="155" height="193"/>
				</div>
			</div>
            
			<form-phone></form-phone>

		</div>
	</div>
</template>
<script>
import FormPhone from "@/components/Forms/Auth/FormPhone";
export default {
	name: "phone",
	components: { FormPhone }
};
</script>