<template>
	<form @submit.prevent="submit" class="form-event" action="@" method="POST">
		<input type="hidden" name="title" value="Продвинуть видео">
		<order-alert v-if="isAuth"></order-alert>
		<div class="form-item">
			<label class="form-label" for="link">Ссылка на видео</label>
			<input v-model="formData.video_link" class="form-input" name="link" id="link" placeholder="https://example-video" data-msg-required="Заполните поле ссылка на видео" required>
		</div>
		<div class="form-item">
			<label class="form-label" for="select">Жанр, стиль, направление</label>
			<div class="select-wrap">
				<select v-model="formData.video_style" class="form-select" name="select" id="select" data-msg-required="Выберите один из вариантов" required>
					<option value="Хип-хоп, Рэп">Хип-хоп, Рэп</option>
					<option value="Рок-музыка">Рок-музыка</option>
					<option value="Джаз">Джаз</option>
				</select>
			</div>
		</div>
		<div class="form-item">
			<div class="form-label">Где продвигать?</div>
      <div class="checkbox-group">
        <Platforms ref="platforms"/>
      </div>
		</div>
		<div class="form-item">
			<label class="form-label" for="text1">Аудитория</label>
			<textarea v-model="formData.audience" class="form-input form-textarea" name="text1" id="text1" placeholder="Опишите свою аудиторию" rows="3" data-msg-required="Заполните поле Аудитория" required></textarea>
			<p class="field-text">Подробно опишите свою потенциальную целевую аудиторию. <b>Например:</b> страна Россия, женщины, от 20 до 35 лет, домохозяйки, много смотрят ТВ, любят песни в жанре поп-музыки</p>
		</div>
		<div class="form-item">
			<label class="form-label" for="text2">Описание к видео</label>
			<textarea v-model="formData.video_description" class="form-input form-textarea" name="text2" id="text2" placeholder="Опишите свое видео" rows="3" data-msg-required="Заполните поле описание к видео" required></textarea>
			<p class="field-text">Подробное описание к песне в свободной форме (пресс-релиз, авторы музыки и слов, слова благодарности или любой другой текст, который Вы бы хотели, чтобы слушатели увидели)</p>
		</div>
		<order-btn></order-btn>

    <loading-spinner v-show="orderCreating"></loading-spinner>

	</form>
</template>
<script>
import {mapGetters} from "vuex";
import Platforms from "@/components/Misc/Platforms";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";
import OrderBtn from "@/components/Misc/OrderBtn";
import OrderAlert from "@/components/Misc/OrderAlert";
export default {
	name: "form-video-promo",
  data() {
	  return {
	    acceptRules: false,
      formData: {
	      form_key: 'vp',
        video_link: '',
        video_style: 'Хип-хоп, Рэп',
        platforms: '',
        audience: '',
        video_description: ''
      }
    }
  },
  validations: {
    acceptRules: {
      checked: value => value === true
    }
  },
  computed: mapGetters(['orderCreating', 'attemptCreationOrder', 'firstOrderError']),
  components: {
    LoadingSpinner, 
    Platforms,
    OrderBtn,
		OrderAlert,
    
  },
  methods: {
    async submit() {
      this.formData.platforms = this.$refs.platforms.getSelected();

      this.$store.commit('setAttemptCreationOrder', true);

      await this.$store.dispatch('createOrder', this.formData);

      if(this.$store.getters.isAuth) {
        await this.$router.push('/order-msg');
      } else {
        await this.$router.push('/register');
      }
    }
  },
  mounted() {
    this.$store.commit('resetOrderForm');
  }
};
</script>