<template>
  <div>
    <div>
      <span style="font-weight: bold;">Заказ - {{order.form_descriptor.name}}</span>
    </div>
    <div>
      <span style="font-weight: bold;">Статус заказа - {{order.order_status.name}}</span>
    </div>
    <div>
      <span style="font-weight: bold;">Статус оплаты - {{order.payment_status.name}}</span>
    </div>
    <div>
      <button
          v-if="order.form_descriptor.amount > 0 && order.payment_status.id == 1"
          @click.prevent="pay"
          class="alert success-reg"
          style="border: 0; cursor: pointer;"
      >
        Оплатить заказ - {{order.form_descriptor.amount / 100}} ₽
      </button>
    </div>
    <div>
      <loading-spinner v-show="loader"></loading-spinner>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSpinner from "@/components/Misc/LoadingSpinner";

export default {
  data() {
    return {
      loader: false
    }
  },
  props: {
    order: Object
  },
  computed: mapGetters(['attemptPayment']),
  components: {LoadingSpinner},
  methods: {
    async pay() {
      if(this.attemptPayment) return;
      this.loader = true;

      let redirectUrl = await this.$store.dispatch('yandexPayment', this.order.id);
      window.open(redirectUrl, '_blank');

      this.loader = false;
    }
  }
}
</script>

<style scoped>

</style>